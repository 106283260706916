import styled  from 'styled-components';

export const Text = styled.span`

left: 53px;
top: 294px;
font-family: 'Nunito';
font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 40px;
letter-spacing: 0.3px;
text-transform: capitalize;
color: ${props => props.color || "#2B3990"};
text-align: justify;
margin-left: 1rem;
  `
