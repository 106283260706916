
import { CustomForm } from "../../atoms/Form";
import BoldText from '../../atoms/BoldText';
import {
    Button, Form, Divider,
} from 'antd';
import { LimitWrapper, ItemWrapper, Footer } from './styles';
import PropTypes from "prop-types";
import InputField from "../../atoms/Input";

export default function NewWalletForm({ isModal }) {
    return (

        <CustomForm>
            <BoldText>Create New Wallet</BoldText>
            <Divider />
            <ItemWrapper>
                <Form.Item>
                    <InputField
                        rounded
                        transparent
                        type="text"
                        name="name"
                        placeholder="Wallet Name"
                    />
                </Form.Item>
                <Form.Item>
                    <InputField
                        type="number"
                        name="amount"
                        placeholder="Amount (UGX)"
                    />
                </Form.Item>
                <LimitWrapper>
                    <Form.Item>
                        <InputField
                            name="weekly"
                            placeholder="Weekly Limit"

                            type="number"
                        />
                    </Form.Item>
                    <Form.Item >
                        <InputField
                            transparent
                            name="monthly"
                            placeholder="Monthly Limit"

                        />
                    </Form.Item>
                </LimitWrapper>
            </ItemWrapper>
            {!isModal &&
                <Footer>
                    <Form.Item style={{ width: '50%' }}>
                        <Button type="primary">Ceate Wallet</Button>
                        <Button type="secondary">Cancel</Button>

                    </Form.Item>
                </Footer>
            }
        </CustomForm>
    );
}
NewWalletForm.propTypes = {
    isModal: PropTypes.bool,
}