import Smiley from "../../../assets/images/notfound.png";
import { StyledDiv, StyledP } from "./styles";
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <StyledDiv>
      <img src={Smiley} alt="404"/>
      <StyledP>Page not found</StyledP>
      <Link to="/dashboard">Return to Dashboard</Link>

    </StyledDiv>
  );
}
