
import React, { useState } from 'react';
import {
    Button, Form, Input, Radio, Select,
    InputNumber, Checkbox, Divider, Upload, Typography
} from 'antd';
import { Wrapper, StyledForm } from "./styles";
import InputField from '../../atoms/Input';
import NewContactForm from '../Contacts/newContactForm';

const { TextArea } = Input;


export const IndividualForm = ({ savedPayee, addNewContact, handleClose, handleOk }) => {
    const [value, setValue] = useState(savedPayee || "Existing Contact");
    const [addNew, setAddNew] = useState(addNewContact);

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };
    const handleAddNew = () => {
        setAddNew(!addNewContact)
        console.log('radio checked', addNew);

    };
    const handleConfirm = () => {
        handleOk();
    };
    const handleCancel = () => {
        handleClose();
    };

    return (
        <>

            <Wrapper>


                <StyledForm>
                    <Form.Item label="Sending from: ">
                        <Select placeholder="Wallet"
                            options={[
                                {
                                    value: 'clientPayments',
                                    label: 'Client payments',
                                },
                                {
                                    value: 'investments',
                                    label: 'Investments',
                                },
                                {
                                    value: 'installment',
                                    label: 'Installment',
                                },
                                {
                                    value: 'staffSalary',
                                    label: 'Staff Salary',
                                }

                            ]}>
                            <Select.Option value="demo">Demo</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Sending to:">
                        <Radio.Group onChange={onChange} value={value}>
                            <Radio value={savedPayee?.length ? savedPayee : "Existing Contact"} onClick={() => setAddNew(false)} > {savedPayee?.length ? savedPayee : "Existing Contact"} </Radio>
                            <Radio value="pear" onClick={handleAddNew}> New Contact </Radio>
                        </Radio.Group>
                    </Form.Item>

                    {addNew && <NewContactForm handleClose={handleCancel} handleOk={handleConfirm} />}
                    <Form.Item label="Amount:">
                        <InputField placeholder='Amount' type="number" />
                    </Form.Item>
                    <Form.Item label="Add description:">
                        <TextArea rows={4} placeholder="Description" />
                    </Form.Item>





                    <Form.Item valuePropName="checked">
                        <Checkbox value="A">Add Withdrawal Fees</Checkbox>
                        <Checkbox value="B">Add Withdrawal Tax</Checkbox>
                    </Form.Item>
                </StyledForm>
            </Wrapper>
        </>
    );
}
export default IndividualForm;