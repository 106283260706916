import PropTypes from "prop-types";
import { Field } from "./styles";

export function InputField(props) {
  return <Field {...props} />;
}

export default InputField;

InputField.propTypes = {
  props: PropTypes.object,
};
