import { useState } from "react";
// import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Input from "../Input";
import { PasswordField } from "./styles";
import PropTypes from "prop-types";

export default function PasswordInput({ name, errors, hasError, ...rest }) {
  const [passwordShown, setState] = useState(false);
  const togglePassword = () => setState(prev => !prev);
  const type = passwordShown ? "text" : "password";

  return (
    <>
      <PasswordField>
        <Input
          {...{ ...rest, type, name }}
        //   hasError={hasError}
        //   error={hasError(name) && errors[name] ? errors[name][0] : ""}
        />
        <p>
          {passwordShown ? (
            <VisibilityOutlinedIcon onClick={togglePassword} />
          ) : (
            <VisibilityOffOutlinedIcon onClick={togglePassword} />
          )}
        </p>
      </PasswordField>
    </>
  );
}

PasswordInput.propTypes = {
  name: PropTypes.string,
  errors: PropTypes.object,
  hasError: PropTypes.func,
  rest: PropTypes.object,
};
