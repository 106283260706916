import styled from "styled-components";
export const StyledDiv = styled.div`
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img{
    height: 25%;

  }
  margin:auto;
  width: 50%;


`;
export const StyledHeader = styled.p`
  font: normal normal 600 173px/180px Raleway;
  letter-spacing: 0px;
  color: #cbcbcb;
`;
export const StyledP = styled.p`
  font: normal normal normal 63px/74px Raleway;
  letter-spacing: 0px;
  color: #cbcbcb;
`;
