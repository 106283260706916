import PropTypes from "prop-types";
import SideNavComponent from "./content";
import { SmallScreenWrapper, BigSreenWrapper } from "./styles";
export default function SideNavBar({ isSmallScreen, showSideNav ,toggleSideNav}) {
  return (
    <>
      <BigSreenWrapper>
        <SideNavComponent isSmallScreen={isSmallScreen} toggleSideNav={toggleSideNav} showSideNav={showSideNav} />
      </BigSreenWrapper>
      {showSideNav && (
        <SmallScreenWrapper>
          <SideNavComponent
            isSmallScreen={isSmallScreen}
            showSideNav={showSideNav}
          />
        </SmallScreenWrapper>
      )}
    </>
  );
}

SideNavBar.propTypes = {
  isSmallScreen: PropTypes.bool,
  showSideNav: PropTypes.bool,
};
