import PropTypes from "prop-types";
import {
  CardHeader,
  CardFooter,
  SunHeading,
  CardStyled,
  CardContent,
} from "../Card/style";
import { FlexContainer } from "../../atoms/FlexContainer";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Button } from "antd";

export default function ResponsiveCard(props) {
  const {
    bg,
    title,
    description,
    btnTxt,
    width,
    lbg,
    color,
    pd,
    bgfooter,
    children,
    btnTxtAction,
  } = props;
  return (
    <>
      <CardStyled width={width} bg={bg} color={color} lbg={lbg}>
        <CardHeader columns color={color}>
          <Typography variant="h5">{title}</Typography>
        </CardHeader>

        <CardContent color={color}>
          <SunHeading>{description}</SunHeading> <br></br>
          <FlexContainer vCenter mb="-5px">
            {children}
          </FlexContainer>
        </CardContent>

        <Divider variant="middle" />

        <CardFooter
          vCenter
          bg={bg}
          bblr="16px"
          bbrr="16px"
          pd={pd}
          bgfooter={bgfooter}
        >
          <Button onClick={btnTxtAction} type="primary" ghost>
            {btnTxt}
          </Button>
        </CardFooter>
      </CardStyled>
    </>
  );
}

ResponsiveCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  btnTxt: PropTypes.string,
  handleButtonClick: PropTypes.func,
  width: PropTypes.string,
  lbg: PropTypes.string,
  color: PropTypes.string,
  pd: PropTypes.string,
  bgfooter: PropTypes.string,
  bg: PropTypes.string,
};
