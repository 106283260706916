import { FlexContainer } from "../../atoms/FlexContainer";
import styled from "styled-components";
import { Table } from "antd";

export const CardStyled = styled.div`
  border: solid 2px gray;
  height: 100%;
  background: #ffffff;
  border: 2px solid #efeeeb;
  border-radius: 12px;
  width: ${(props) => props.width};
  background: ${(props) => props.bg};
  linear-gradientbackground: ${(props) => props.lbg};
  color:${(props) => props.color};
  .ant-table-tbody>tr>td{
    padding:4px 10px;
  }

`;

export const CardWrapper = styled.div`
  width: 100%;
  border-radius: 19px;
  text-align: left;
  position: relative;
  margin-bottom: 30px;

  .banner {
    width: 100%;
    height: 160px;
    margin-bottom: -5px;
  }

  @media (min-width: 1025px) {
    width: calc(94% / 3);
  }

  @media (min-width: 1480px) {
    width: calc(94% / 4);

    .banner {
      height: 180px;
    }
  }
`;

export const CardHeader = styled(FlexContainer)`
  height: 10%;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  /* identical to box height */

  letter-spacing: 0.3px;
  text-transform: capitalize;
  width: 100%;
  padding: 1rem;
  color:${props => (props.color ? props.color : '#353535')};
  h5 {
    line-height: 2rem;
  }
  h6 {
    margin-right: -5rem;
    float: right;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 2rem;
    /* identical to box height */

    letter-spacing: 0.3px;
    text-transform: capitalize;

    color:${props => (props.color ? props.color : '#00aeef')};

  }

  .logo {
    width: 60px;
    height: 60px;
    margin-left: -15px;
    margin-bottom: -5px;
    margin-right: -5px;
  }
`;

export const CardFooter = styled(FlexContainer)`
  padding:${props => (props.pd ? props.pd : '25px 20px')};

  background: ${props => (props.bgfooter ? props.bgfooter : 'inear-gradient(121.68deg, #2B3990 -3.41%, #01ABED 86.47%)')};
  background-size: cover;
  background-repeat: no-repeat;

  a {
    font-size: 13px;
    font-weight: bold;
  }

  button {
    width: unset;

    &.more {
      font-size: 14px;
      color: white;
      font-weight: bold;
      border: none;
      background: none;
      cursor: pointer;
    }
  }
`;

export const StyledAnalytics = styled(FlexContainer)`
  width: 100%;
  cursor: pointer;
  position: relative;
  flex-direction: row;
  text-transform: capitalize;
  flex-flow: row;
  justify-content: space-evenly;
  img {
    height: rem;
  }
`;

export const CardContent = styled(FlexContainer)`
  flex-direction: column;
  text-transform: capitalize;
  color:${props => (props.color ? props.color : '#353535')};

  margin: 0.5rem 1rem 2rem 1rem;
  p {
    color:${props => (props.color ? props.color : '#9c9c9c')};
  }
  img {
    height: 12px;
  }
  height:35%;
`;
export const CardContentAnalytic = styled(FlexContainer)`
  flex-direction: row;
  text-transform: capitalize;
  flex-flow: row;
  margin: 0.5rem 1rem 2rem 1rem;
  p {
    color:${props => (props.color ? props.color : '#9c9c9c')};
  }
`;
export const SunHeading = styled.span`
  letter-spacing: 0.3px;
  text-transform: capitalize;

  color:${props => (props.color ? props.color : '#353535')};
  li {
    font-size: 11px;
    text-align: start;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1.8rem;

  }
`;
export const StyledPercentatge = styled.span`
  letter-spacing: 0.3px;
  text-transform: capitalize;
  margin-right: 4px;
  color: #81f129;
`;
export const StyledTable = styled(Table)`
box-sizing: border-box;

width: 488px;
left: 302px;
top: 909px;

background: #F5F5F5;
border: 2px solid #969696;
border-radius: 12px;`