
import { CustomForm } from "../../atoms/Form";
import BoldText from '../../atoms/BoldText';
import {
    Button, Form, Input, Radio, Select,
    InputNumber, Checkbox, Divider, Space, Typography
} from 'antd';
import { LimitWrapper, ItemWrapper, Footer } from './styles';
import PropTypes from "prop-types";

export default function TransferForm({ isModal }) {
    const tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };
    return (

        <CustomForm>
            <BoldText>Transfer between Wallet</BoldText>
            <Divider />
            <ItemWrapper>

            <Form.Item label="Sending from: ">
                        <Select placeholder="Wallet"
                            options={[
                                {
                                    value: 'clientPayments',
                                    label: 'Client payments',
                                },
                                {
                                    value: 'investments',
                                    label: 'Investments',
                                },
                                {
                                    value: 'installment',
                                    label: 'Installment',
                                },
                                {
                                    value: 'staffSalary',
                                    label: 'Staff Salary',
                                }

                            ]}>
                            <Select.Option value="demo">Demo</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Sending to: ">
                        <Select placeholder="Wallet"
                            options={[
                                {
                                    value: 'clientPayments',
                                    label: 'Client payments',
                                },
                                {
                                    value: 'investments',
                                    label: 'Investments',
                                },
                                {
                                    value: 'installment',
                                    label: 'Installment',
                                },
                                {
                                    value: 'staffSalary',
                                    label: 'Staff Salary',
                                }

                            ]}>
                            <Select.Option value="demo">Demo</Select.Option>
                        </Select>
                    </Form.Item>
                <Form.Item>
                    <InputNumber

                        type="number"
                        name="amount"
                        placeholder="Amount (UGX)"
                    />
                </Form.Item>
             
            </ItemWrapper>
            {!isModal &&
                <Footer>
                    <Form.Item style={{ width: '50%' }}>
                        <Button type="primary">Ceate Wallet</Button>
                        <Button type="secondary">Cancel</Button>

                    </Form.Item>
                </Footer>
            }
        </CustomForm>
    );
}
TransferForm.propTypes = {
    isModal: PropTypes.bool,
}