import HomePage from "./components/pages/home";
import GeneralLayout from "./components/hoc/GeneralLayout";
import LoginPage from "./components/pages/Auth/login";
import SignUpPage from "./components/pages/Auth/signup";
import DashLayout from "./components/templates/DashLayout";
import DashboardPage from "./components/pages/dashboard";
import NotFoundPage from "./components/pages/404page";
import VerificationPage from "./components/pages/Auth/verify";
import PaymentPage from "./components/pages/payment";
import WalletPage from "./components/pages/wallet";
import ContactsPage from "./components/pages/contacts";
import CreditPage from "./components/pages/Credit";
import { BulkUserUpload } from "./components/ui/molecules/Contacts/bulkAdd";
const routes = [
  {
    path: "/",
    title: "Dashboard",
    component: DashboardPage,
    layout: DashLayout,
  },
  {
    path: "/login",
    title: "Login",
    component: LoginPage,
    layout: GeneralLayout,
  },
  {
    path: "/register",
    title: "Register",
    component: SignUpPage,
    layout: GeneralLayout,
  },
  {
    path: "/verify",
    title: "Verify",
    component: VerificationPage,
    layout: GeneralLayout,
  },
  {
    path: "/dashboard",
    title: "Dashboard",
    component: DashboardPage,
    layout: DashLayout,
    // protected: true,
  },
  {
    path: "/payments",
    title: "Payment",
    component: PaymentPage,
    layout: DashLayout,
  },
  {
    path: "/wallet",
    title: "Wallet",
    component: WalletPage,
    layout: DashLayout,
  },
  {
    path: "/credit",
    title: "Credit",
    component: CreditPage,
    layout: DashLayout,
  },
  {
    path: "/contacts",
    title: "Contacts",
    component: ContactsPage,
    layout: DashLayout,
  },
  {
    path: "/contacts/bulk-add",
    title: "Bulk Add",
    component: BulkUserUpload,
    layout: DashLayout,
  },
  {
    path: "*",
    title: "NotFound",
    component: NotFoundPage,
    layout: DashLayout,

  },
];
export default routes;
