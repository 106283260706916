import PropTypes from "prop-types";
import {
  CardHeader,
  CardFooter,
  SunHeading,
  CardStyled,
  StyledPercentatge,
  CardContent,
} from "./style";
import { FlexContainer } from "../../atoms/FlexContainer";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Rise from "../../../../assets/images/vector3.png";

export default function Card(props) {
  const {
    bg,
    title,
    description,
    btnTxt,
    width,
    lbg,
    color,
    pd,
    bgfooter
  } = props;
  return (
    <>
      <CardStyled width={width} bg={bg} color={color} lbg={lbg}>
        <CardHeader columns color={color}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="h6">Withdraw</Typography>
        </CardHeader>

        <CardContent color={color}>
          <SunHeading>{description}</SunHeading> <br></br>
          <FlexContainer vCenter mb="-5px">
            <StyledPercentatge>
              <img src={Rise} alt="percentage" /> +8.10%{" "}
            </StyledPercentatge>
            <Typography variant="body2">Than last month Balance</Typography>
          </FlexContainer>
        </CardContent>

        <Divider variant="middle" />

        <CardFooter vCenter bg={bg} bblr="16px" bbrr="16px" pd={pd} bgfooter={bgfooter}>
          <Typography>{btnTxt}</Typography>
        </CardFooter>
      </CardStyled>
    </>
  );
}

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  btnTxt: PropTypes.string,
  handleButtonClick: PropTypes.func,
  width:PropTypes.string,
  lbg:PropTypes.string,
  color:PropTypes.string,
  pd:PropTypes.string,
  bgfooter:PropTypes.string,


};
