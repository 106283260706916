import styled from "styled-components";
import { FlexContainer } from "../../atoms/FlexContainer";

export const Wrapper = styled(FlexContainer)`
  // height: 85vh;
  width: 100%;
  padding:1rem;
`;

export const FormSectionWrapper = styled(FlexContainer)`
  width: 50%;
  height:90%;
  position:absolute;
  right:0;
  flex-direction:column;
  .ant-upload-wrapper .ant-upload-list{
    height:20vh;
  }
  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    width:45%;
    height: 60%;
  }
 
`;
export const FormRightSectionWrapper = styled(FlexContainer)`
  width: 50%;
  height: 100%;
  position:absolute;
  left:4%;
  .ant-form-item{
    width:80%;
    font-size:2rem;
    }
    .ant-input-number {
      width:100%;
    }
    .ant-checkbox-wrapper{
      width:100%;
      margin:auto;
    }
    .ant-radio-group{
      float:left;
      display: flex;

    }
    .ant-select.ant-select-in-form-item {
      text-align:left;
    }
 
`;
export const FormFooter = styled(FlexContainer)`
  // width: 50%;
  // position:absolute;
  // bottom: 0;
  // left: 5%;
  .ant-form-item .ant-form-item-control-input-content{
    display:flex;
    justify-content:space-between;

  }
.ant-form-item{
  margin-left:-2rem;
}
`;
export const StyledForm = styled.form`
display:flex;
flex-direction:Column;
height: inherit;
`;