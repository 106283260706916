import styled from "styled-components";

export const LogoWrapper = styled.div`
width:5rem;
height:5rem;
display: flex;
img{    
    width: 100%;
    height: 100%;
    object-fit: contain;}
}
`;
