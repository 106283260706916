import React, { useState } from 'react';
import { Table } from 'antd';
import OverViewComponent from './overview';
import { Wrapper } from "./styles";

function WalletBalances() {

    const [visibleState, setVisibleState] = useState(false);

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);

    };
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            filters: [
                {
                    text: 'Joe',
                    value: 'Joe',
                },
                {
                    text: 'Jim',
                    value: 'Jim',
                },
                {
                    text: 'Submenu',
                    value: 'Submenu',
                    children: [
                        {
                            text: 'Green',
                            value: 'Green',
                        },
                        {
                            text: 'Black',
                            value: 'Black',
                        },
                    ],
                },
            ],
            // specify the condition of filtering result
            // here is that finding the name started with `value`
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.name.length - b.name.length,
            sortDirections: ['descend'],
            render: (text) => <a>{text}</a>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        setVisibleState(!visibleState);
                    },
                }
            }
        },
        {
            title: 'last Updated',
            dataIndex: 'lastUpdated',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.lastUpdated - b.lastUpdated,
        },
        {
            title: 'Amount UGX',
            dataIndex: 'amount',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.amount - b.amount,
        },
        {
            title: 'Weekly Limit UGX',
            dataIndex: 'weeklyLimit',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.weeklyLimit - b.weeklyLimit,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filters: [
                {
                    text: 'Active',
                    value: 'active',
                },
                {
                    text: 'Deactive',
                    value: 'active',
                },
            ],
            onFilter: (value, record) => record.status.indexOf(value) === 0,
        },
    ];
    const data = [
        {
            key: '1',
            id: 8906544,
            name: 'App Dev ',
            lastUpdated: "March 10, 2022",
            amount: 225000,
            weeklyLimit: 100000,
            status: "Active",
            children: [
                {
                    key: 11,
                    name: 'App Dev Detailed',
                    lastUpdated: "March 10, 2022",
                    amount: 225000,
                    weeklyLimit: 100000,
                    status: "Active",
                }

            ],

        },
        {
            key: '2',
            id: 8906544,
            name: 'Digital Marketing',
            lastUpdated: "March 10, 2022",
            amount: 725000,
            weeklyLimit: 100,
            status: "Active",

        },
        {
            key: '3',
            id: 8906544,
            name: 'UI Design',
            lastUpdated: "March 10, 2022",
            amount: 5000,
            weeklyLimit: 50000,
            status: "Deactive",

        },
        {
            key: '4',
            id: 8906544,

            name: 'Jim Red',
            lastUpdated: "March 10, 2022",
            amount: 55400,
            weeklyLimit: 100,
            status: "Active",

        },
        {
            key: '5',
            id: 8906544,

            name: 'Installment',
            lastUpdated: "March 10, 2022",
            amount: 25000,
            weeklyLimit: 1000000,
            status: "Deactive",

        },
        {
            key: '6',
            id: 8906544,
            name: 'App Dev ',
            lastUpdated: "March 10, 2022",
            amount: 225000,
            weeklyLimit: 100000,
            status: "Active",

        },
        {
            key: '7',
            id: 8906544,
            name: 'Digital Marketing',
            lastUpdated: "March 10, 2022",
            amount: 725000,
            weeklyLimit: 100,
            status: "Active",

        },
        {
            key: '8',
            id: 8906544,
            name: 'UI Design',
            lastUpdated: "March 10, 2022",
            amount: 5000,
            weeklyLimit: 50000,
            status: "Deactive",

        },
        {
            key: '9',
            id: 8906544,

            name: 'Jim Red',
            lastUpdated: "March 10, 2022",
            amount: 55400,
            weeklyLimit: 100,
            status: "Active",

        },
        {
            key: '10',
            id: 8906544,

            name: 'Installment',
            lastUpdated: "March 10, 2022",
            amount: 25000,
            weeklyLimit: 1000000,
            status: "Deactive",

        },
    ];

    return (
        <Wrapper>
            <Table columns={columns} dataSource={data} onChange={onChange}
                pagination={false}
                expandable={{
                    expandedRowRender: (record) => (
                        <p
                            style={{
                                margin: 0,
                            }}
                        >
                            {record.description}
                        </p>
                    ),
                    rowExpandable: (record) => record.description !== 'Not Expandable',
                }} />
            {
            visibleState && <OverViewComponent handleOnClick={() => setVisibleState(!visibleState)}/>
 } </Wrapper>
    )
}
export default WalletBalances;