import React, { useCallback, useState } from 'react';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SasulaPayLogo from "../../../../assets/images/logoblue.png";
import { LogoWrapper } from "./styles";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import BoldText from "../../atoms/BoldText";
import Input from "../../atoms/Input";
import PhoneInput from "../../atoms/Input";

import PasswordInput from "../../atoms/PasswordInput";
import {
    LoginSocialGoogle,
} from 'reactjs-social-login';
import {

    GoogleLoginButton,

} from 'react-social-login-buttons'

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="/">
                SasulaPayjjh
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}
const theme = createTheme();
const REDIRECT_URI = window.location.href;

export default function SignUpPageComponent() {
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get("email"),
            password: data.get("password"),
        });
    };


    const onLoginStart = useCallback(() => {
        alert('login start')
    }, [])

    const onLogoutSuccess = useCallback(() => {
        alert('logout success')
    }, [])
    const [state, setState] = useState({
        values: {
            firstName: "",
            email: "",
            password: "",
            accountName: "",
            telephone: "",
        },
        errors: {},
        touched: {},
        isValid: false,
        socialAuth: false,
    });
    const handleChange = ({ target: { name, value } }) => {
        setState(oldState => ({
            ...oldState,
            values: {
                ...oldState.values,
                [name]: value,
            },
            touched: {
                ...oldState.touched,
                [name]: true,
            },
        }));
    };
    const hasError = f => (state.touched[f] && state.errors[f] ? true : false);
    const { values, errors, isValid, passwordShown } = state;
    return (

        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                <Grid item xs={8} component={Paper} elevation={6} sm={4} md={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <LogoWrapper>
                            <img src={SasulaPayLogo} alt="logo" />
                        </LogoWrapper>



                        <Box
                            sx={{
                                my: 1,
                                mx: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <BoldText>
                                WELCOME TO HUBPAY
                            </BoldText>     </Box>
                        <Box

                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{
                                width: 400,
                                height: 400,
                                p: 4,
                            }}
                            mt={2}

                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        name="First Name"

                                        type="text"

                                        id="firstName"
                                        placeholder="First Name"
                                        autoFocus
                                    />

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        name="Last Name"
                                     
                                        type="text"

                                        id="lastName"
                                        placeholder="Last Name"
                                        autoFocus
                                    />

                                </Grid>
                                <Grid item xs={12}>

                                    <Input
                                        rounded
                                        transparent
                                        placeholder="Email"
                                        type="text"
                                        name="email"
                                        autoComplete="email"

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PhoneInput
                                        rounded
                                        // lighter
                                        placeholder="Telephone number"
                                        type="text"
                                        name="telephone"
                                        value={values.telephone}
                                        errors={errors}
                                        hasError={hasError}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordInput margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        placeholder="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password" />
                                    <PasswordInput margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        placeholder="Confirm Password"
                                        type="password"
                                        id="password-confirm"
                                        autoComplete="current-password" />
                                </Grid>

                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 1, mb: 2 }}
                                style={{ background: " #00AEEF" }}
                            >
                                Sign Up
                            </Button>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    {/* <LoginSocialGoogle
                                        isOnlyGetToken
                                        client_id="kiuytrter9"
                                        // onLoginStart={onLoginStart}
                                        onResolve
                                        onReject={(err) => {
                                            console.log(err)
                                        }}
                                    > */}
                                      <Typography variant="subtitle">   Already have an account? </Typography>
                                    <Link href="/login" variant="body2">
                                        SIGN IN
                                    </Link>
                                    {/* <GoogleLoginButton text='Continue with Google' style={{ width: "100%",marginTop:"2rem"  }} /> */}
                                    {/* </LoginSocialGoogle> */}
                                  
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    {/* <Copyright sx={{ mt: 5 }} /> */}
                </Grid>
                <Grid
                    item
                    xs={8}
                    sm={4}
                    md={6}
                    sx={{
                        backgroundImage:
                            "url(https://source.unsplash.com/random/?blue?solid)",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                            t.palette.mode === "light"
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
            </Grid>
        </ThemeProvider>
    );
}