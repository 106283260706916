import styled from "styled-components";
import { FlexContainer } from "../../atoms/FlexContainer";
import { Button, Card } from 'antd';

export const Wrapper = styled(FlexContainer)`
  height: 100%;
  left: 0;
  z-index: 2;
  justify-content:space-between;
  width: 100vw;
  flex-wrap: nowrap;
  button{
    width:100%
  }

`;

export const GroupsCards=styled(Card)`
width:100%;
height:50%;
overflow-x:auto;

  padding: unset;
  height: 50ex;
  .ant-card-body{
    padding: 0 0.5rem;
  }

`;