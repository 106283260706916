import { Wrapper } from "../Wallet/styles";
import { Divider, Form } from "antd";
import CreditPageCard from "../../molecules/Credit";
import { useState } from "react";
import MomoCreditForm from "../../molecules/Credit/momopay";
import { FlexContainer } from "../../atoms/FlexContainer";
import { StyledWrapper } from "./styles";
export const CreditPageContent = () => {
  const [isFlipped, setFlipped] = useState(false);
  const [isMomo, setMomo] = useState(true);
  const [isRequest, setIsRequest] = useState(false);
  const [isHubPay, setIsHubpay] = useState(false);

  const handleClickMomo = (e) => {
    e.preventDefault();
    setIsHubpay(false);
    setIsRequest(false);
    setMomo((prevState) => !prevState);
  };
  const handleClickRequest = (e) => {
    e.preventDefault();
    setMomo(false);
    setIsHubpay(false);
    setIsRequest((prevState) => !prevState);
  };
  const handleClickHubpay = (e) => {
    e.preventDefault();
    setMomo(false);
    setIsRequest(false);
    setIsHubpay((prevState) => !prevState);
  };
  return (
    <FlexContainer spaceAround width="100%" height="100%">
  
      <Wrapper>
        <FlexContainer spaceAround vCenter bg={"#1da1f2"} pd={"1rem"} br={"4px"} width={"96%"}>
          <CreditPageCard
            title="Mobile Money"
            flip={isFlipped}
            description="Make Mobile money deposits from your phone. MTN or Airtel"
            action={handleClickMomo}
            btnTxt="Proceed to deposit"
            bg={"#F0F8FF"}
          />
          <CreditPageCard
            bg={"#B9D9EB"}
            title="Request Payment"
            description="Request payments from other mobile money users to your account"
            flip={isFlipped}
            action={handleClickRequest}
            btnTxt="Proceed to deposit"
          />
          <CreditPageCard
            bg={"#87CEEB"}
            title="Hubpay"
            description="Request funding from another HubPay account"
            flip={isFlipped}
            action={handleClickHubpay}
            btnTxt="Proceed to deposit"
          />
        </FlexContainer>
        {/* </Space> */}
        <Divider />
        <StyledWrapper>
          <Form
            style={{
              width: " 40%",
              margin: "auto",
            }}
          >
            {isMomo && <MomoCreditForm isMomo={isMomo} />}
            {isRequest && (
              <MomoCreditForm isRequest={isRequest} isHubPay={isHubPay} />
            )}
            {isHubPay && (
              <MomoCreditForm isRequest={isRequest} isHubPay={isHubPay} />
            )}
          </Form>
        </StyledWrapper>
      </Wrapper>
    </FlexContainer>
  );
};
export default CreditPageContent;
