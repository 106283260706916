import {  Modal } from 'antd';
import PropTypes from "prop-types";

function FormModal({ open, handleOk, handleCancel,children,confirmLoading,title }) {


    return (
        <>
       
            <Modal
                title={title}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                {children}
            </Modal>
        </>
    );
};
export default FormModal;
FormModal.propTypes = {
    confirmLoading:PropTypes.bool,
    children: PropTypes.node,
    handleOk: PropTypes.func,
    handleCancel: PropTypes.func,
    open: PropTypes.bool,
    title:PropTypes.string,
};