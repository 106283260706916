import { InboxOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Upload } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
const { Dragger } = Upload;
const text = <h3>Bulk Contacts Upload</h3>;

export const BulkUserUpload = () => {
  const [form] = Form.useForm();
  const [uploading,] = React.useState(false);
  const [fileList, setFileList] = React.useState([]);
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 }
  };
 
  const onFormSubmit = (values) => {
    // do actions here for saving
    console.log(values);
  };
  const buttonItemLayout = { wrapperCol: { span: 20, offset: 11 } };

  const handleUpload = () => {
    const formData = new FormData();
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const newFileList = [...fileList, file];
      setFileList(newFileList);
      return false;
    },
    fileList
  };
  return (
    <div>
    
      <Form
        {...formItemLayout}
        layout='horizontal'
        form={form}
        onFinish={onFormSubmit}
      >
        {text}
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag csv file to this area to upload</p>
          <p className="ant-upload-hint">
                        Upload up to 5000 contacts at once:
                        Your file must include these fields: Name, email, Phone
                        If you're not sure how to create CSV file use this template to get started.
                        Choose a file
          </p>
        </Dragger>
        <Form.Item label="" {...buttonItemLayout}>
          <Divider type='vertical' />
          <p>For help on how to upload, go to <Link to='/account/help/bulk_upload'>Help Center</Link></p>
          <p><a href={require('../../../../assets/template.csv')} download>Download Template</a></p>
        </Form.Item>
        <Form.Item label="" {...buttonItemLayout}>
          <Divider type='vertical' />
          <Checkbox >First Column Contains Column Names</Checkbox>
        </Form.Item>
        <Divider />
        <Divider />
        <Form.Item {...buttonItemLayout}>
          <Button
            type="primary"
            loading={uploading}
            disabled={fileList.length === 0}
            onClick={handleUpload}>
            {uploading ? 'Uploading' : 'Upload'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};