import { useState } from 'react';
import { Space, Table, Button,Tooltip } from 'antd';
import { ShortcutsWrapper, OverViewWrapper } from "./styles";
import TableCard from '../../atoms/Card/tableCard';
import ResponsiveTable from '../../atoms/Table';
import BoldText from '../../atoms/BoldText';
import { RollbackOutlined } from '@ant-design/icons';
import Transfer from "../../../../assets/images/moneytransfer.png";
import Pay from "../../../../assets/images/pay.png";
import { RoundedImage } from '../../atoms/RoundedImage';
import { Redirect, Link } from 'react-router-dom';
import FormModal from '../../atoms/Modal';
import TransferForm from './transfer';
function OverViewComponent({ handleOnClick }) {
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
    const [visibleState, setVisibleState] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const showModal = () => {
        setVisibleState(true);
    };
    const handleWalletTrans=()=>{
        console.log("clicked");
        setVisibleState(!visibleState);

    }
    const handleOk = () => {
        setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        setTimeout(() => {
            setVisibleState(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisibleState(false);
    };
    return (
        <OverViewWrapper>
            <Space
                direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                    width: "100%",
                }}
                align="start"
            >

                <Space
                    direction="horizontal"
                    size="middle"
                    style={{
                        display: 'flex',
                        justifyContent: "space-between",
                        width: "95%",
                        alignItems
                            : "center"
                    }}
                    align="start"
                ><BoldText>Current Wallet Status</BoldText>
                    <Button ghost type="primary" onClick={handleOnClick}><RollbackOutlined toneColor="blue" />Back to Wallets
                    </Button>
                </Space>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: "50%" }}><TableCard /></div>

                    <ShortcutsWrapper>
                    <Tooltip title="Make a payment" placement="left">

                    <Link to="/dashboard/payments"> <RoundedImage src={Pay}/></Link></Tooltip>
                        <Tooltip title="Transfer between wallets" placement="left">
                    <RoundedImage src={Transfer}  onClick={showModal}/></Tooltip>
                    </ShortcutsWrapper>
                </div>
                <BoldText>Recent Wallet Transfer Summary</BoldText>
                <ResponsiveTable

                />
            </Space>
            <FormModal
                    open={visibleState}
                    handleOk={handleOk}
                    confirmLoading={confirmLoading}
                    handleCancel={handleCancel}
                    >  <TransferForm isModal={visibleState}/></FormModal>
          
        </OverViewWrapper>
    )
}
export default OverViewComponent;