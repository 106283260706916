import React from "react";

import {CardStyled,StyledTable} from './style';
export default function TableCard() {
  
    const columns = [
        {
            dataIndex: "name",
            width: 150
        },
        {
            dataIndex: "value",
            width: 150
        },
       
    ];
    const data = [
        {
            key: 1,
            name: `Name`,
            
            value: 'LipaNode',
        },
        {
            key: 2,
            name: `ID`,
            
            value: 689704,
          
        }, {
            key: 3,
            name: `Created By`,
            
            value: "Riat",
          
        }, {
            key: 4,
            name: `Last Payment`,
            
            value: '19 August 2019, 08:15 PM',
          
        }, {
            key: 5,
            name: `Last Top Up`,
            
            value: '29 August 2019, 07:15 AM',
          
        }, {
            key: 6,
            name: `Current AMount`,
            
            value: 400000,
          
        },{
            key:7,
            name:"Status",
            value:"Active"
        }

    ];




    return (
        <div style={{marginLeft: "8%",height:"100%",width:"100%"}}>

            <CardStyled 
                style={{ padding: "0px", margin:"auto"}}
                
            >
                <StyledTable
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    scroll={{ x: true }}
                />
               
            </CardStyled>
        </div>
    );

}