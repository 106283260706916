import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SasulaPayLogo from "../../../../assets/images/logoblue.png";
import { LogoWrapper } from "./styles";
import Input from "../../atoms/Input";
import PasswordInput from "../../atoms/PasswordInput";
import BoldText from "../../atoms/BoldText";
import {

  GoogleLoginButton,

} from 'react-social-login-buttons'

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        SasulaPay
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const theme = createTheme();

export default function LoginPageComponent() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid item xs={8} component={Paper} elevation={6} sm={4} md={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LogoWrapper>
              <img src={SasulaPayLogo} alt="logo" />
            </LogoWrapper>

            {/* <Typography component="h2" variant="h4"> */}
            {/* <BoldText>
              WELCOME TO HUBPAY 
              </BoldText>            */}
            {/* </Typography> */}

            <Box
              sx={{
                my: 1,
                mx: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <BoldText>
                WELCOME TO HUBPAY
              </BoldText>     </Box>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                width: 400,
                height: 400,
                p: 4,
              }}
              mt={2}
            >

              <Input
                rounded
                transparent
                placeholder="Email"
                type="text"
                name="email"
                autoComplete="email"

              />
              <PasswordInput margin="normal"
                required
                fullWidth
                name="password"
                // label="Password"
                type="password"
                id="password"
                autoComplete="current-password" />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{ background: " #00AEEF" }}
              >
                     <Link href="/dashboard" variant="body2" style={{color:"white",textDecoration:"none"}}>
                     Sign In
                  </Link>
                {/* Sign In */}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                {/* <Grid item>
                  <Link href="/register" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid> */}
              </Grid>
              <Grid container justifyContent="center">
                <Grid item padding={"2rem"}> <Typography variant="subtitle">   Don't have an account? </Typography>
                  <Link href="/register" variant="body2">
                    SIGN UP
                  </Link>
                  {/* <LoginSocialGoogle
                                        isOnlyGetToken
                                        client_id="kiuytrter9"
                                        // onLoginStart={onLoginStart}
                                        onResolve
                                        onReject={(err) => {
                                            console.log(err)
                                        }}
                                    > */}
                  {/* <GoogleLoginButton text='Continue with Google' style={{ width: "100%", marginTop:"2rem" }} /> */}
                  {/* </LoginSocialGoogle> */}

                </Grid>
              </Grid>

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={8}
          sm={4}
          md={6}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random/?lightblue)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </ThemeProvider>
  );
}
