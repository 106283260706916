import styled from "styled-components";

export const RoundedImage = styled.img`
  border-radius: 50%;
  margin-right: 10px;
  width: ${props =>
    props.small ? "35px" : props.width ? props.width : "135px"};
  height: ${props =>
    props.small ? "35px" : props.height ? props.height : "135px"};
    cursor:pointer;
  @media (min-width: 768px) {
    width: ${props =>
      props.small ? "46px" : props.width ? props.width : "135px"};
    height: ${props =>
      props.small ? "46px" : props.height ? props.height : "135px"};
  }
`;
