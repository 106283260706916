
// import { Typography } from '@mui/material';
// import Button from '@mui/material/Button';
import { PlusOutlined } from '@ant-design/icons';

import React, { useState } from 'react';
import {
    Button, Form, Input, Radio, Select,
    InputNumber, Checkbox, Divider, Upload, Typography



} from 'antd';

import { Wrapper,StyledForm } from "../styles";
const { TextArea } = Input;
const { Title } = Typography;



export default function UtilitiesForm() {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('vertical');
    const onFormLayoutChange = ({ layout }) => {
        setFormLayout(layout);
    };
    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 5,
                },
                wrapperCol: {
                    span: 16,
                },
            }
            : null;
    const buttonItemLayout =
    {
        wrapperCol: {
            span: 14,
            offset: 4,
        },
    }

    return (
        <>

            <Wrapper>

                {/* <StyledForm>
                <Form.Item >
                    <InputNumber placeholder='Account Number' type="number" />
                </Form.Item>
                <Form.Item>
                    <Select placeholder="Bill"
                        options={[
                            {
                                value: 'yaka',
                                label: 'Electricity(yaka) ',
                            },
                            {
                                value: 'umeme',
                                label: 'Electricity(umeme) ',
                            },
                            {
                                value: 'payTv',
                                label: 'Pay Tv',
                            }, {
                                value: 'ura',
                                label: 'URA',
                            }, {
                                value: 'water',
                                label: 'Water',
                            },

                        ]} >
                        <Select.Option value="demo">Demo</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Select placeholder="Wallet"
                        options={[
                            {
                                value: 'investment',
                                label: 'Investment ',
                            },
                            {
                                value: 'installment',
                                label: 'Installment ',
                            },
                            {
                                value: 'monthlyExpenses',
                                label: 'Monthly expenses',
                            }, {
                                value: 'monthlyBills',
                                label: 'Monthly Utility Bills',
                            },

                        ]} >
                        <Select.Option value="demo">Demo</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item >
                    <InputNumber placeholder='Amount' type="number" />
                </Form.Item>

                <Form.Item name="disabled" valuePropName="checked">
                    <Checkbox value="A">Add Withdrawal Fees</Checkbox>
                    <Checkbox value="B">Add Withdrawal Tax</Checkbox>
                    <Checkbox value="D">Amount in Send Currency</Checkbox>
                </Form.Item>

                </StyledForm> */}


<StyledForm> 
    <Form.Item label="Send from:">
                    <Select placeholder="Wallet"
                        options={[
                            {
                                value: 'clientPayments',
                                label: 'Client payments',
                            },
                            {
                                value: 'investments',
                                label: 'Investments',
                            },
                            {
                                value: 'installment',
                                label: 'Installment',
                            },
                            {
                                value: 'staffSalary',
                                label: 'Staff Salary',
                            }

                        ]}>
                        <Select.Option value="demo">Demo</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item >
                    <InputNumber placeholder='Account Number' type="number" />
                </Form.Item>
                <Form.Item label="Utlity type" >
                <Select placeholder="Wallet"
                           options={[
                            {
                                value: 'yaka',
                                label: 'Electricity(yaka) ',
                            },
                            {
                                value: 'umeme',
                                label: 'Electricity(umeme) ',
                            },
                            {
                                value: 'payTv',
                                label: 'Pay Tv',
                            }, {
                                value: 'ura',
                                label: 'URA',
                            }, {
                                value: 'water',
                                label: 'Water',
                            },

                        ]} >
                        <Select.Option value="demo">Demo</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item >
                    <InputNumber placeholder='Amount' type="number" />
                </Form.Item>
                <Form.Item>
                    <TextArea rows={4} placeholder="Description" />
                </Form.Item>
             
          
                </StyledForm>
            </Wrapper>
        </>
    );
}
