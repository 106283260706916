import styled from "styled-components";

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: ${props => (props.wrapReverse ? "wrap-reverse" : "wrap")};
  flex-direction: ${props =>
    props.columns ? "column" : props.reverse ? "row-reverse" : "row"};
  justify-content: ${props =>
    props.hCenter
      ? "center"
      : props.right
      ? "right"
      : props.spaceBetween
      ? "space-between"
      : props.spaceAround
      ? "space-around"
      : "left"};
  align-items: ${props =>
    props.vCenter ? "center" : props.bottom ? "flex-end" : "flex-start"};
  width: ${props => props.width};
  height: ${props => props.height};
  background: ${props => props.bg};
  border-radius: ${props => props.br};
  border-top-left-radius: ${props => props.btlr};
  border-top-right-radius: ${props => props.btrr};
  border-bottom-left-radius: ${props => props.bblr};
  border-bottom-right-radius: ${props => props.bbrr};
  margin: ${props => props.mg};
  margin-top: ${props => props.mt};
  margin-left: ${props => props.ml};
  margin-bottom: ${props => props.mb};
  margin-right: ${props => props.mr};
  padding: ${props => props.pd};
  padding-top: ${props => props.pt};
  padding-left: ${props => props.ll};
  padding-bottom: ${props => props.pb};
  padding-right: ${props => props.pr};
  cursor: ${props => (props.pointer ? "pointer" : "")};
  position: ${props => props.position};
  z-index: ${props => props.zIndex};
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  right: ${props => props.right};
  left: ${props => props.left};
`;

//the content should be styled from elsewhere
