import styled from "styled-components";

export const Wrapper = styled.span`
  width: 100%;
  margin-top: 8%;
  margin: auto;
  padding: 2rem;
`;
export const SubscribeInput = styled.input`
  flex: 1;
  width: 40%;
  padding: 1.5rem;
  font-size: 1.1em;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  box-shadow: none;
  border: solid 1px #61dafb;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.19);
`;
export const SubscribeBtn = styled.button`
  padding-right: 12px;
  background-color: #3a459f;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 5px 4px 6px rgba(0, 0, 0, 0.19);
  border: none;
  cursor: pointer;
  cursor: hand;
  font-size: 1.1em;
  height: 4.44rem;
  &:hover {
    background-color: #61dafb;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.19);
  }
  margin-left:-2px;
`;
export const SubscribeButtonText = styled.span`
  padding: 1.4rem;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  font-family: ui-rounded;
`;
