import { useState } from "react";
import PropTypes from "prop-types";
import { FlexContainer } from "../../ui/atoms/FlexContainer";
import { BodyWrapper } from "./styles";
import { screenWidth } from "../../../helpers/getWidth";
import SideNavBar from "../../ui/organisms/Layout/SideNav";
import TopNavBar from "../../ui/organisms/TopNav";

export default function DashLayout({ children }) {
  const handleSmallScreen = () => {
    console.log("dsmall sceen", window.innerWidth);
    return screenWidth < 768 ? true : false;
  };

  const isSmallScreen = handleSmallScreen();

  const [showSideNav, setShowSideNav] = useState(false);
  const toggleSideNav = () => {
    setShowSideNav(!showSideNav);
  };

  return (
    <>
      <FlexContainer columns position="relative">
        <FlexContainer bg="#e5e4e266" width="100vw" height="100vh">
          <SideNavBar isSmallScreen={isSmallScreen} showSideNav={showSideNav} toggleSideNav={toggleSideNav}/>
          <BodyWrapper hCenter>
            <TopNavBar toggleSideNav={toggleSideNav} showSideNav={showSideNav}
            />

            {" "}
            {children}
          </BodyWrapper>
        </FlexContainer>
      </FlexContainer>
    </>
  );
}
DashLayout.propTypes = {
  children: PropTypes.node,
  userData: PropTypes.object,
  path: PropTypes.string.isRequired,
};
