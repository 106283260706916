import styled from "styled-components";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { FlexContainer } from "../../atoms/FlexContainer";

export const MenuIcon = styled(MenuOutlinedIcon)`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const NavWrapper = styled(FlexContainer)`
width: 100%;
.css-hip9hq-MuiPaper-root-MuiAppBar-root{
  background-color:transparent;
  color:black;
  box-shadow:none;
}
.css-yz9k0d-MuiInputBase-input{
  background: #FFFFFF;
  border: 2px solid #EFEEEB;
  border-radius: 12px;
  width: 20rem;
}
.css-37jdci-MuiTypography-root{
  line-height:4;
}
.css-hyum1k-MuiToolbar-root{
  padding:0;
}
`;
