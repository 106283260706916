import PropTypes from "prop-types";
import { FlexContainer } from "../../atoms/FlexContainer";
import NavLink from "../../molecules/Layout/NavLink";

import { sideBarRouteLinks } from "./data";
import { Wrapper } from "./styles";
import { LogoWrapper } from "./styles";
import SasulaPayLogo from "../../../../assets/images/logoblue.png";
import LogoutIcon from '@mui/icons-material/Logout';
import React, { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';

export default function SideNavComponent({ isSmallScreen, profile,toggleSideNav, showSideNav }) {
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Wrapper columns isSmallScreen={isSmallScreen}>
        <FlexContainer
          height="100vh"
          width="100%"
          pt="15px"
          columns
          position="relative"
        >
                {showSideNav ? (
            <MenuIcon size="30" onClick={toggleSideNav} />
          ) : (
            <MenuIcon size="35" onClick={toggleSideNav} />
          )}
          <LogoWrapper >
            <img src={SasulaPayLogo} alt="logo" mg="inherit" />
          </LogoWrapper>
          {/* <MenuOutlined onClick={showDrawer} /> */}
          {sideBarRouteLinks?.map(routeLink => {

            return <NavLink key={routeLink?.path} {...routeLink} />;
          })}
          <NavLink
            path="/login"
            activeIcon={<LogoutIcon />}
            icon={<LogoutIcon />}
            label="Logout"
            className="info"
          />
          {/* <Drawer
            title="Menu"
            placement="left"
            onClick={() => setVisible(false)}
            onClose={onClose}
            open={open}
            isSmallScreen={isSmallScreen}
          >
            <LogoWrapper >
              <img src={SasulaPayLogo} alt="logo" mg="inherit" />
            </LogoWrapper>

            {sideBarRouteLinks.map(routeLink => {

              return <NavLink key={routeLink.path} {...routeLink} />;
            })}
            <NavLink
              path="/login"
              activeIcon={<LogoutIcon />}
              icon={<LogoutIcon />}
              label="Logout"
              className="info"
            />
          </Drawer> */}

        </FlexContainer>
      </Wrapper>
    </>
  );
}

SideNavComponent.propTypes = {
  isSmallScreen: PropTypes.bool,
  showSideNav: PropTypes.bool,
  profile: PropTypes.object,
};
