import styled from "styled-components";

export const StyledFormWrapper = styled.form`
display:flex;
flex-direction:Column;
height: 100%;
width:100%
`;
export const Form = styled.form`
  width: 100%;
  height: ${props => props.height};
  margin: ${props => props.mg};
  margin-top: ${props => props.mt || "40px"};
  margin-left: ${props => props.ml};
  margin-bottom: ${props => props.mb};
  margin-right: ${props => props.mr};

  @media (min-width: 768px) {
    width: ${props => props.width};
  }
`;
export const Label = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
`;
