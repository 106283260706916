import InputField from "../../atoms/Input";
import { Button, Radio } from "antd";

export const MomoCreditForm = ({ isRequest, isHubPay, isMomo }) => {
  return (
    <>
      {isHubPay && (
        <Radio.Group name="radiogroup" defaultValue="individual">
          
          <Radio value="indivivual">indivivual</Radio>
          <Radio value="organization">organization</Radio>
        </Radio.Group>
      )}
      {(isHubPay || isRequest) && (
        <InputField type="text" name="name" placeholder="Name" />
      )}
      {isMomo && (
        <InputField
          type="text"
          name="name"
          placeholder="Mobile Money registered name"
        />
      )}
      {isRequest && (
        <InputField type="number" name="name" placeholder="Phone Number" />
      )}
      <InputField type="number" name="name" placeholder="Amount" />
      <InputField type="text" name="name" placeholder="Reason" />
      <div className="formbtns">
        <Button type="primary">Send</Button>
        <Button>Cancel</Button>
      </div>
    </>
  );
};
export default MomoCreditForm;
