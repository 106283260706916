
// import { Typography } from '@mui/material';
// import Button from '@mui/material/Button';
import { PlusOutlined } from '@ant-design/icons';

import React, { useState } from 'react';
import {
    Button, Form, Input, Radio, Select,
    Checkbox, Divider, Upload, Typography
} from 'antd';

import { Wrapper, FormSectionWrapper, StyledForm } from "../styles";
import InputField from '../../../atoms/Input';

const { TextArea } = Input;
const { Title } = Typography;



export default function BankForm() {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('vertical');
    const onFormLayoutChange = ({ layout }) => {
        setFormLayout(layout);
    };
    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 5,
                },
                wrapperCol: {
                    span: 16,
                },
            }
            : null;
    const buttonItemLayout =
    {
        wrapperCol: {
            span: 14,
            offset: 4,
        },
    }

    return (
        <>

            <Wrapper>

<StyledForm> 
    <Form.Item label="Send from:">
                    <Select placeholder="Wallet"
                        options={[
                            {
                                value: 'clientPayments',
                                label: 'Client payments',
                            },
                            {
                                value: 'investments',
                                label: 'Investments',
                            },
                            {
                                value: 'installment',
                                label: 'Installment',
                            },
                            {
                                value: 'staffSalary',
                                label: 'Staff Salary',
                            }

                        ]}>
                        <Select.Option value="demo">Demo</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Send to:" >
                    <Radio.Group>
                        <Radio value="apple"> Existing Bank Account </Radio>
                        <Radio value="pear"> New Bank Account </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item >
                    <InputField placeholder='Amount' type="number" />
                </Form.Item>
                <Form.Item>
                    <TextArea rows={4} placeholder="Description" />
                </Form.Item>
             
          
                </StyledForm>
            </Wrapper>
        </>
    );
}
