import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import ClassName from "classnames";
import NavLinkWrapper from "./styles";
import LinkLabel from "./LinkLabel";

function NavLink({ activeIcon, icon, label, path, className = "", routes }) {
  const {
    location: { pathname },
    push,
  } = useHistory();
  const currentLocation = useLocation();
  return (
    <NavLinkWrapper
      className={ClassName(className, { active: pathname === path })}
      onClick={() => { push(path) }}
    >
      {pathname === path ? activeIcon : icon}
      {!routes?.length ? <LinkLabel
        mg="auto 12px"
        className={ClassName({ active: pathname === path })}
        to={{
          pathname: path,
          state: { prevPath: currentLocation.pathname },
        }}
      >
        {label}
      </LinkLabel> : <ul>
        {routes?.map((route) => {
          <li key={route.label}>
            <LinkLabel
              mg="auto 12px"
              className={ClassName({ active: pathname === route.subPath })}
              to={{
                pathname: route.subPath,
                state: { prevPath: currentLocation.pathname },
              }}
            >
              {route.label}
            </LinkLabel></li>
        })}
      </ul>}
    </NavLinkWrapper>
  );
}

export default NavLink;

NavLink.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  path: PropTypes.string,
  activeIcon: PropTypes.object,
  className: PropTypes.string,
};
