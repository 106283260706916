import { ContentWrapper, CardsWrapper,WidgetsWrapper, SectionWrapper } from "./styles";
import Card from "../../atoms/Card";
import CardAnalytics from "../../atoms/Card/AnalyticsCard";
import TransactionHistoryContent from "../TransactionHistoryWidget";
import QuickTransferContent from "../QuickTransfer";
import StyledInviteWidget from "../../atoms/Invite";
export default function HomePageContent() {


  return (
    <>

      <ContentWrapper>
        <CardsWrapper hCenter width="100%" mt="45px">
          <Card title="Balance" description='$5,379.37' btnTxt='ID:98765432345678' width={'25%'}></Card>
          <CardAnalytics title="Transaction Overview" btnTxt='ID:98765432345678'></CardAnalytics>

        </CardsWrapper>
        <SectionWrapper>
        <TransactionHistoryContent />
        <WidgetsWrapper>
        <QuickTransferContent />
        <StyledInviteWidget />
        </WidgetsWrapper>
        </SectionWrapper>
      </ContentWrapper>
    </>
  );
}
