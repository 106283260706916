import React from 'react';
import { Table } from 'antd';
import { RoundedImage } from '../RoundedImage';
import dummyImage from "../../../../assets/images/ellipse.png";
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (record) => {
            return (
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <         RoundedImage
                        className="profilePic"
                        width="2rem"
                        height="2rem"
                        src={dummyImage}
                        alt="pic" />
                    <div>
                        <span>{record.name}</span>
                    </div>
                </div>
            );
        }
    },
    {
        title: 'Date & Time',
        dataIndex: 'dateTime',
        key: 'dateTime',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
    },

    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },

    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Mobile No / Acc No.',
        dataIndex: 'number',
        key: 'number',
    },
    {
        title: 'Status',
        key: 'status',
        dataIndex: 'status'
    },
];

const data = [
    {
        key: '1',
        name: 'John Doe',
        dateTime:"March 10, 2022 4pm",
        amount: 32,
        type:"TopUp",
        description: '...',
        number:+2345678999,
        status: 'Approved',
    },
    {
        key: '2',
        name: 'Jane Doe',
        dateTime:"March 10, 2022 4pm",
        amount: 3200,
        type:"Payment",
        description: 'Payment for initial implementation',
        number:123456544,
        status: 'Pending',
    },
    {
        key: '3',
        name: 'Jim Green',
        dateTime:"March 10, 2022 4pm",
        amount: 3867542,
        type:"Payment",
        description: 'Payment for Documents',
        number:+234578902,
        status: 'Approved',
    },
    {
        key: '4',
        name: 'Nusrat',
        dateTime:"March 10, 2022 4pm",
        amount: 8976543,
        type:"TopUp",
        description: ' .....',
        number:+234578902,
        status: 'Denied',
    },
];

const ResponsiveTable = () => (
    <Table columns={columns} dataSource={data}         pagination={false}

    style={{overflow:'auto', width:"100%"}}/>
);

export default ResponsiveTable;
