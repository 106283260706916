import styled from "styled-components";
import { FlexContainer } from "../../atoms/FlexContainer";

export const Wrapper = styled(FlexContainer)`
  height: 80%;
  width: 100%;
  background: white;
  padding:1rem;
  left: 0;
  z-index: 2;
  overflow-x: auto;
  border-radius:2rem;
  .ant-tabs {
width:100%
}
.ant-btn span{
  vertical-align: super;
  padding: 0 0.5rem;
}
`;

export const ButtonsWrapper = styled(FlexContainer)`
  width: 100%;
  justify-content:space-evenly;

`;
