import Card from "../../atoms/Card";
import { Wrapper } from "../Wallet/styles";
import ResponsiveCard from "../../atoms/CustomCard";
import { Space } from "antd";
import ReactCardFlip from 'react-card-flip';
import { useState } from "react";
import { InfoCircleTwoTone,CloseCircleTwoTone} from '@ant-design/icons';

export const CreditPageCard = ({children, title,description, flip,action, btnTxt,bg}) => {
const [isFlipped, setFlipped]=useState(flip);
const handleClick=(e)=> {
    e.preventDefault();
    setFlipped(prevState => (!prevState));
  }
    return (
            <ReactCardFlip isFlipped={isFlipped}
            flipDirection="horizontal" flipSpeedFrontToBack={1}
            containerStyle={{width:"25%"}}>
                <ResponsiveCard title={title} width="100%"  btnTxt={btnTxt} btnTxtAction={action} bg={bg}>
                {children}
                <InfoCircleTwoTone onClick={handleClick} />
                </ResponsiveCard>
                <ResponsiveCard title={title} width="100%" btnTxt={btnTxt} btnTxtAction={action} style={{flexWrap:"wrap"}} >
                {description}
                <CloseCircleTwoTone onClick={handleClick}/>

                </ResponsiveCard>
                </ReactCardFlip>
    )
}
export default CreditPageCard;