import PersonIcon from '@mui/icons-material/Person';
import { Wrapper } from "./styles";
import GroupsIcon from '@mui/icons-material/Groups';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Tabs, Button } from 'antd';
import React, { useState } from 'react';

import PaymentForm from '../../molecules/Payment';
import BulkPaymentForm from '../../molecules/Payment/Bulk/bulkPaymentsForm';
import UtilitiesPaymentForm from "../../molecules/Payment/Utility";
import BankPaymentForm from '../../molecules/Payment/Bank';
import FormModal from '../../atoms/Modal';
import NewContactForm from '../../molecules/Contacts/newContactForm';
export const PaymentPageContent = () => {

    const [tabsList, setTabsList] = useState([
        { tab: 'Individual', key: 'tab1', Icon: PersonIcon },
        { tab: 'Bulk Payment', key: 'tab2' },
        { tab: 'Utility Bills', key: 'tab3' },
        { tab: 'Bank Payment', key: 'tab4' },

    ]);
    const payTo = localStorage.getItem("payTo");
    console.log(payTo);

    const [state, setState] = useState({
        activeTab: "tab1",
        payee: payTo,
        addNewContact: false,
        confirmLoading:false,
        modalText:""
    })

    const changeTab = activeKey => {
        console.log(activeKey);
        setState({
            activeTab: activeKey
        });
    };
    const handleSetNewAdd = () => {
        setState({addNewContact: true});
    };
    const handleOk = () => {
          setState({modalText:'The modal will be closed after two seconds', confirmLoading:true});
        setTimeout(() => {
            setState({addNewContact:false, confirmLoading:false});
        }, 2000);
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setState({addNewContact:false});
    };
    return (
        <>
            <Wrapper>
                <Tabs
                    defaultActiveKey="tab1"
                    activeKey={state.activeTab}
                    onChange={changeTab}

                >
                    <tabsList.TabPane key="tab1" tab={

                        <Button icon={<PersonIcon />} >Individual</Button>

                    } >
                        <PaymentForm savedPayee={state.payee} addNewConact={state.addNewContact} handleCancel={handleCancel} handleOk={handleOk}/>
                    </tabsList.TabPane>
                    <tabsList.TabPane key="tab2" tab={

                        <Button icon={<GroupsIcon />}>Bulk Payment</Button>

                    }>
                        <BulkPaymentForm />
                    </tabsList.TabPane>
                    <tabsList.TabPane key="tab3" tab={

                        <Button icon={<ReceiptLongIcon />}>Utility Bills</Button>

                    }>
                        <UtilitiesPaymentForm />
                    </tabsList.TabPane>
                    <tabsList.TabPane key="tab4" tab={

                        <Button icon={<AccountBalanceIcon />}>Bank Payment</Button>

                    }>
                        <BankPaymentForm />
                    </tabsList.TabPane>
                    {/* {tabsListList.map((tabInfo, index, Icon)=> {
                       <tabsList.TabPane key={tabInfo.key} tab={<Button icon={<Icon />}>{tabInfo.tab}</Button>}>
                    <PaymentForm />
                </tabsList.TabPane>
                        })} */}
                </Tabs>
                {/* {state.addNewConact} */}
                
            </Wrapper>
        </>
    );
}
export default PaymentPageContent;