import styled from "styled-components";

export const Wrapper = styled.div`
  min-width: 100%;
//   background: rgb(42, 42, 114);
  background: linear-gradient(
    158deg,
    rgba(42, 42, 114, 1) 2%,
    rgba(42, 42, 114, 1) 5%,
    rgba(0, 212, 255, 1) 100%
  );
  color: white;
  display: inline_flex;
  flex-direction: row;
  max-height: 30vh;
  width: 100vw;
  padding:0;
`;
export const FooterWrapper = styled.div`
  margin: auto;
//   max-width: 60%;
  width: 50%;
`;
export const FooterTextWrapper = styled.div`
  max-width: 50%;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  margin: 0;
`;
export const FooterIconWrapper = styled.ul`
  max-width: 50%;
  color: white;
  display: inline-block;
  margin: 0;
  margin-left: 3rem;
  list-style-type: none;
  padding: 2rem;
  margin-top: -7rem;
  float: right;
  li {
    padding: 5px;
    align-items:center;
  }
`;
