import "./App.css";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";

import routes from "./routes";
import RouteWithLayout from "./components/hoc/RouteWithLayout";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";


function App({ signOut, user }) {
  return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            {routes.map((route) => (
              <RouteWithLayout exact {...route} key={route.path} />
            ))}
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>{" "}
      </div>
  );
}

export default App;
