import styled from "styled-components";
import { FlexContainer } from "../../atoms/FlexContainer";

export const Wrapper = styled(FlexContainer)`
  height: 100%;
  width: 100%;
  background: white;
  position: absolute;
  left: 0;
  z-index: 2;
  overflow-x:auto;
  @media (min-width: 768px) {
    width: 250px;
    position: unset;
  }
`;
export const LogoWrapper = styled.div`
width:10rem;
height:5rem;
display: flex;
margin: ${props => props.mg};
img{    
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: saturate(2);


  }

padding 2rem;
`;
export const BigSreenWrapper = styled(Wrapper)`
  display: none;

  @media (min-width: 768px) {
    display: unset;
    width: 250px;
    position: unset;
    height: 100%;
  }
`;

export const SmallScreenWrapper = styled(Wrapper)`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;

  @media (min-width: 768px) {
    display: none;
  }
`;