import PropTypes from "prop-types";
import { StyledFormWrapper } from "./styles";

export function CustomForm({ name, onSubmit,children}) {
  return (
  <StyledFormWrapper  {...{ name, onSubmit }}>
{children}
  </StyledFormWrapper>);
}

export default CustomForm;

CustomForm.propTypes = {
  props: PropTypes.object,
  children:PropTypes.node,
};
