import React, { useState } from 'react';
import { Space, Form, Button } from 'antd';
import { ContactsTable, CreateContactWrapper, CreateButtonWrapper, CreateGroupInput } from './styles';

function CreateContact({ setDisable, isCreateGroup }) {

    const [visibleState, setVisibleState] = useState(false);

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);

    };

    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            key: 'key',

        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            editable: true,

        },

        {
            title: 'Mail',
            dataIndex: 'mail',
            key: 'mail',
            editable: true,

        },
        {
            title: 'Phone Number',
            dataIndex: 'number',
            key: 'number',
            editable: true,

        },
        {
            title: 'Group',
            dataIndex: 'group',
            key: 'group',
            editable: true,

        },
        {
            title: 'KYC Verification',
            dataIndex: 'kyc',
            key: 'kyc',
            render: (text, record) => (
                <Space size="middle">
                    <ul>
                        <li>Network</li>
                        <li>Mobile Money</li>
                        <li>Contact name</li>
                    </ul>

                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            name: '',
            dateTime: "",
            mail: "",
            group: "",
            kyc: '...',
            number: '',
        },

    ];
    const [tableData, setTableData] = useState(data);

    const handleAdd = () => {

        setTableData([...tableData, { key: tableData.length + 1 }]);
    };
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = (form, key) => {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const newData = [...tableData];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setTableData(newData);
                setEditingKey('');
            } else {
                newData.push(data);
                setTableData(newData);
                setEditingKey('');
            }
        });
    };

    return (
        <CreateContactWrapper>
            <Form >
                {isCreateGroup && <CreateGroupInput placeholder="Group Name..." />}
                <ContactsTable
                    bordered

                    columns={columns} dataSource={tableData}
                    pagination={false}
                />
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        display: 'flex',
                    }}>

                    <div className='btns'>
                        <Button
                            onClick={handleAdd}
                            type="primary"
                            ghost
                        >
                            Add a row
                        </Button>
                    </div>
                    <CreateButtonWrapper>
                        <Space
                            direction="horizontal"
                            size="middle"
                            style={{
                                display: 'flex',
                            }}>
                            <Button type="primary">Submit</Button>
                            <Button onClick={setDisable}>Cancel</Button>
                        </Space>
                    </CreateButtonWrapper>
                </Space>
            </Form>
        </CreateContactWrapper>
    )
}
export default CreateContact;