import styled from "styled-components";

import { FlexContainer } from "../../atoms/FlexContainer";

export const CardWrapper = styled(FlexContainer)`
height: 48vh;
background: linear-gradient(124.31deg, #2B3990 7.01%, #00AEEF 95.31%);
border-radius: 12px;
overflow:auto;
margin-top: 1rem;
color:#FFFFFF;
`;

export const CardContentWrapper = styled.div`
width: 80%;
height: 3rem;
border-radius: 12px;
`;
