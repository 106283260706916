import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SasulaPayLogo from "../../../../assets/images/logoblue.png";
import { LogoWrapper } from "./styles";
import Input from "../../atoms/Input";
import PasswordInput from "../../atoms/PasswordInput";
import BoldText from "../../atoms/BoldText";
import ReactInputVerificationCode from "react-input-verification-code";
import OtpInput from 'react18-input-otp';



const theme = createTheme();

export default function VerifyPageComponent() {
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get("email"),
            password: data.get("password"),
        });
    };
    const [state, setState] = useState({
        otp: '',
        errors: {},
        touched: {},
        isValid: false,
        socialAuth: false,
    });
    const handleChange = (otp) => setState({ otp });

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                <Grid item xs={8} component={Paper} elevation={6} sm={4} md={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <LogoWrapper>
                            <img src={SasulaPayLogo} alt="logo" />
                        </LogoWrapper>

                        {/* <Typography component="h2" variant="h4"> */}
                        <BoldText>
                            VERIFICATION CODE
                        </BoldText>
                        {/* </Typography> */}
                        <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{
                                width: 400,
                                height: 400,
                                p: 4,
                            }}
                            mt={2}
                        >

                            <OtpInput
                                value={state.otp}
                                onChange={handleChange}
                                numInputs={4}
                                separator={<span>  </span>}
                                inputStyle={{
                                    width: "5rem",
                                    height: "6rem",
                                    fontSize: "4rem",
                                    color: "blue",
                                    margin: "0.5rem"
                                }}

                            />
                            <Typography>Didn’t recieve code?</Typography>
                            <Typography> <Link href="/login" variant="body2">Resend OTP</Link></Typography>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                style={{ background: " #00AEEF" }}
                            >
                                Verify
                            </Button>
                            <Grid container>

                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={8}
                    sm={4}
                    md={6}
                    sx={{
                        backgroundImage:
                            "url(https://source.unsplash.com/random/?lightblue)",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                            t.palette.mode === "light"
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
            </Grid>
        </ThemeProvider>
    );
}
