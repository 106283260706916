import {  Route } from "react-router-dom";
import React from "react";

import PropTypes from "prop-types";

export default function RouteWithLayout({
  layout: Layout,
  component: Component,
  ...props
}) {
 
  return (
    <Route
      {...props}
      render={() => (
        <Layout {...{ ...props }}>
          <Component {...{ ...props }} />
        </Layout>
      )}
    />
  );
}
RouteWithLayout.propTypes = {
  layout: PropTypes.func,
  component: PropTypes.func,
  protected: PropTypes.bool,
};
