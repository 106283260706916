import styled from "styled-components";
import { Card } from "antd";

export const StyledWrapper = styled.div`
  border: solid 2px gray;
  height: 30%;
  background: #ffffff;
  border: 2px solid #efeeeb;
  border-radius: 12px;
  display: flex;
    width: 93%;
    padding: 2rem;
 input{
    margin-bottom: 1rem;
 }
 button{
    width:30%;
    margin: 0 1rem 0 1.2rem;
 }
 .formbtns{
    display:flex;
    justify-content:flex-start;
}
`;
