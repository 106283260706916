import React, { useState } from 'react';
import { Space, Typography, Divider } from 'antd';
import { ContactsTable, Wrapper } from './styles';
function ContactList({ isGroupOverview, groupName, handleClose }) {

    const [visibleState, setVisibleState] = useState(false);
    const [payee, setPayee] = useState('');

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const onExpand = (expanded, record) => {
        if (expanded) {
            setExpandedRowKeys([record.key]);
        } else {
            setExpandedRowKeys([]);
        }
    };
    const handleSetPayee = (name) => {
        setPayee(name);
        console.log("payee",payee);
        localStorage.setItem("payTo", payee);

    }
        ;
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);

    };
    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            key: 'key',

        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

        },

        {
            title: 'Mail',
            dataIndex: 'mail',
            key: 'mail',
        },
        {
            title: 'Phone Number',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Group',
            dataIndex: 'group',
            key: 'group',
        },
        {
            title: 'KYC Verification',
            dataIndex: 'kyc',
            key: 'kyc',
            render: (text, record) => (
                <Space size="middle">
                    <ul>
                        <li>Network</li>
                        <li>Mobile Money</li>
                        <li>Contact name</li>
                    </ul>

                </Space>
            ),
        },


        {
            title: 'Created',
            dataIndex: 'dateTime',
            key: 'dateTime',
        },
    ];
    const innerColumns = [
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record) => <a href="/payments" onClick={handleSetPayee(record.name)}>Make A Payment</a>,

        },
    ];
    const innerData = [
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
            createdBy: 'Ronald'
        },
        {
            title: 'Actions',
            key: 'operation',
            render: () => <a href="/payment">Make A Payment</a>,
        },
    ];
    const data = [
        {
            key: '1',
            name: 'Nusrat Jahan',
            dateTime: "March 10, 2022 4pm",
            mail: "nusrat@gmail.com",
            group: "TopUp",
            kyc: '...',
            number: +2345678999,
            createdBy: "Ronald"
        },
        {
            key: '2',
            name: 'sabrina Binte',
            dateTime: "March 10, 2022 4pm",
            mail: "sabrina@gmail.com",
            group: "TopUp",
            kyc: '...',
            number: +2345678999,
            createdBy: "Nicholas"

        },
        {
            key: '3',
            name: 'Ian Muriba',
            dateTime: "March 10, 2022 4pm",
            mail: "ian@gmail.com",
            group: "TopUp",
            kyc: '...',
            number: +2345678999,
            operation: 'Make a payment',
            createdBy: "Nicholas"
        },
        {
            key: '4',
            name: 'Nusrat',
            mail: "nusrat@gmail.com",

            dateTime: "March 10, 2022 4pm",
            group: "TopUp",
            kyc: '...',
            number: +2345678999,
            actions: "Make a payment",
            createdBy: "Rita"
        },
    ];

    return (
        <Wrapper>


            <ContactsTable columns={columns} dataSource={data} onChange={onChange}
                pagination={false}
                // expandable={{
                //     expandedRowRender: (record) => (
                //         <p
                //             style={{
                //                 margin: 0,
                //             }}
                //         >
                //             {record.description}
                //         </p>
                //     ),
                //     rowExpandable: (record) => record.description !== 'Not Expandable',
                // }}
                style={{ height: "100%" }}
                expandedRowRender={record => (
                    <ContactsTable
                        dataSource={[record]}
                        columns={innerColumns}
                        pagination={false}
                    />
                )}
                expandable={{
                    expandedRowKeys,
                    onExpand,
                }}
            />
        </Wrapper>
    )
}

export default ContactList;