import styled from "styled-components";
import { FlexContainer } from "../../ui/atoms/FlexContainer";

export const BodyWrapper = styled(FlexContainer)`
  width: 100%;
  position: relative;
  width:100%;
  height: 100%;
    @media (min-width: 768px) {
    height: 100%;
    overflow-x: auto;
    width: calc(100vw - 310px);
    padding: 0 30px 0 30px;

  }
`;
