import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import TransferWithinAStationOutlinedIcon from '@mui/icons-material/TransferWithinAStationOutlined';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import CallReceivedOutlinedIcon from '@mui/icons-material/CallReceivedOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
export const sideBarRouteLinks = [
  {
    label: "Dashboard",
    icon: <DashboardRoundedIcon />,
    activeIcon: <DashboardRoundedIcon />,
    path: "/dashboard",
  },

  {
    label: "Wallet",
    icon: <WalletOutlinedIcon />,
    activeIcon: <WalletOutlinedIcon />,
    path: "/wallet",
  },
  {
    label: "Credit",
    icon: <CallReceivedOutlinedIcon />,
    activeIcon: <CallReceivedOutlinedIcon />,
    path: "/credit",
  },

  {
    label: "Pay",
    icon: <CallMadeOutlinedIcon />,
    activeIcon: <CallMadeOutlinedIcon />,
    path: "/payments",
  },
  {
    label: "Withdraw",
    icon: <TransferWithinAStationOutlinedIcon />,
    activeIcon: <TransferWithinAStationOutlinedIcon />,
    path: "/withdraw",
  },
  {
    label: "Invoices",
    icon: <RequestQuoteOutlinedIcon />,
    activeIcon: <RequestQuoteOutlinedIcon />,
    path: "/invoices",
  },
  {
    label: "Bank & Cards",
    icon: <CreditCardOutlinedIcon />,
    activeIcon: <CreditCardOutlinedIcon />,
    path: "/cards",
  },
  {
    label: "Contacts",
    icon: <ContactsOutlinedIcon />,
    activeIcon: <ContactsOutlinedIcon />,
    path: "/contacts",
  },
  {
    label: "Settings",
    icon: <SettingsOutlinedIcon />,
    activeIcon: <SettingsOutlinedIcon />,
    path: "/settings",
  },

];
