import { WidgetContent, WidgetWrapper, WidgetButton } from "./styles";
import ShareIcon from '@mui/icons-material/Share';
import { Typography } from "@mui/material";
export default function StyledInviteWidget() {
    return (
        <WidgetWrapper>
            <WidgetContent>
                <Typography variant="subtitle" color="white"> Invite your friends to sign up to HUBPAY
                </Typography>

                <WidgetButton>
                <ShareIcon />
                    Invite
                </WidgetButton>
            </WidgetContent>
        </WidgetWrapper>
    );
}
