
import { Typography } from '@mui/material';
// import Button from '@mui/material/Button';
import { PlusOutlined, FileTextTwoTone } from '@ant-design/icons';
import React, { useState } from 'react';
import {
    Button, Form, Input, Radio, Select,
    InputNumber, Checkbox, Divider, Upload,
} from 'antd';
import BoldText from '../../../atoms/BoldText';
import { Wrapper, FormSectionWrapper, FormFooter, FormRightSectionWrapper, StyledForm } from "../styles";
import UtilitiesForm from './utilitybills';
const { TextArea } = Input;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export default function UtilitiesPaymentForm() {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('vertical');
    const onFormLayoutChange = ({ layout }) => {
        setFormLayout(layout);
    };
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 5,
                },
                wrapperCol: {
                    span: 16,
                },
            }
            : null;
    const buttonItemLayout =
    {
        wrapperCol: {
            span: 14,
            offset: 4,
        },
    }
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    return (
        <>

            <Wrapper>

                {/* <FormSectionWrapper> */}
                <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={form}
                    initialValues={{
                        layout: formLayout,
                    }}
                    onValuesChange={onFormLayoutChange}
                    style={{
                        maxWidth: 600,
                    }}
                >

                    <FormRightSectionWrapper>
                        <StyledForm>
                            <BoldText>Pay Utility Bills</BoldText>

                            <UtilitiesForm onChange={handleChange}/>

                            <FormFooter>
                                <Form.Item {...buttonItemLayout}>
                                    <Button type="primary">Submit</Button>
                                    <Button type="secondary">Cancel</Button>

                                </Form.Item>
                            </FormFooter>
                        </StyledForm>
                    </FormRightSectionWrapper>
                    <FormSectionWrapper>
                        <Form.Item valuePropName="fileList">
                            <Upload action="/upload.do" listType="picture-card" onPreview={handlePreview}
                            >
                                <div>
                                    {/* <PlusOutlined /> */}
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        Attach documents (like your invoice or work contract)
                                    </div>
                                    <FileTextTwoTone />

                                </div>
                            </Upload>
                        </Form.Item>
                    </FormSectionWrapper>

                </Form>

            </Wrapper>
        </>
    );
}
