import PropTypes from "prop-types";
import {
  CardHeader,
  SunHeading,
  CardStyled,
  StyledAnalytics,
  CardContentAnalytic,
} from "./style";
import { Typography } from "@mui/material";
import Payment from "../../../../assets/images/groupBlue.png";
import Collection from "../../../../assets/images/groupOrange.png";
import Withdraw from "../../../../assets/images/groupDblue.png";

export default function CardAnalytics(props) {
  const {
    title,
  } = props;
  return (
    <>
      <CardStyled width="70%">
        <CardHeader columns>
          <Typography variant="h5">{title}</Typography>
        </CardHeader>

        <CardContentAnalytic>
          <SunHeading>
            <ul>
                <li>payments</li>
                <li>Collections</li>
                <li>Withdraw</li>
                <li>pending</li>

                </ul>
                </SunHeading> <br></br>
       
            <StyledAnalytics>
              <img src={Payment} alt="payment" />             
               <img src={Collection} alt="collection" />
              <img src={Withdraw} alt="withdraw" />
</StyledAnalytics>
          {/* </FlexContainer> */}
        </CardContentAnalytic>

   
      </CardStyled>
    </>
  );
}

CardAnalytics.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  btnTxt: PropTypes.string,
  handleButtonClick: PropTypes.func,
};
