import { FlexContainer } from "../FlexContainer";
import styled from "styled-components";

export const PasswordField = styled(FlexContainer)`
  width: 100%;
  position: relative;
  margin: 2rem 0;
  p {
    cursor: pointer;
    position: absolute;
    right: 15px;
  }
`;
