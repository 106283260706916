import styled from "styled-components";

import { FlexContainer } from "../../atoms/FlexContainer";
export const Wrapper = styled(FlexContainer)`
box-sizing: border-box;
width: 95%;
flex-direction:column;
height: 30%;
margin-top: 1rem;
background: #FFFFFF;
border: 2px solid #EFEEEB;
border-radius: 12px;
overflow: auto;
.ant-tabs {
    height:30%;
    margin-top: 12rem;
}
.ant-table-wrapper {
    clear: both;
    max-width: 100%;
    width: 100%;
    .ant-table{
        max-height:40vh;

    }
}
`;
export const LimitWrapper = styled.div`
display:flex;
justify-content:space-evenly;
width:100%;
input{
    width:78%;
}

`;
export const ItemWrapper = styled.div`
display:flex;
justify-content:space-evenly;
width:50%;
.ant-form-item {
    width:100%;
}
flex-direction:column;
.ant-input-number {
    width:100%;
}
`;
export const Footer = styled.div`
width:50%;
margin: 0 -1.5rem;
padding: unset;`;

export const OverViewWrapper = styled(FlexContainer)`
height: 100%;
width: 95%;
position: absolute;
background: #ffffff;
z-index:2;
display: flex;
flex-direction: column;
overflow-x:auto;
.ant-space-item{
    width:95%;
}
table{
    width:95%;
}
.ant-tooltip-inner {
    color: yellow;
    background-color: green;
  }
}
`;
export const ShortcutsWrapper = styled.div`
width:28%;
margin:auto;
flex-direction:column;
align-items: flex-start;

padding: unset;
display: flex;
flex-basis: ;
position: absolute;
right: 0;
img{
    width:3rem;
    height:3rem;
    object-fit:contain;
}
`;
