import { CardWrapper, CardContentWrapper } from "./styles";
import { RoundedImage } from "../../atoms/RoundedImage";
import dummyImage from "../../../../assets/images/dummy.png";
import { Divider } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

export default function QuickTransferContent() {


    return (
        <CardWrapper hCenter width="100%" mt="45px">
<CardContentWrapper>
        <List>


                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <RoundedImage
                                className="profilePic"
                                width="100%"
                                height="100%"
                                src={dummyImage}
                                alt="pic" />                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="William Jones" secondary="ID: 57898" />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <RoundedImage
                                className="profilePic"
                                width="100%"
                                height="100%"
                                src={dummyImage}
                                alt="pic" />                                            </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Work" secondary="ID: 57898" />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <RoundedImage
                                className="profilePic"
                                width="100%"
                                height="100%"
                                src={dummyImage}
                                alt="pic" />                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="William Jones" secondary="ID: 57898" />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <RoundedImage
                                className="profilePic"
                                width="100%"
                                height="100%"
                                src={dummyImage}
                                alt="pic" />                                            </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Work" secondary="ID: 57898" />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <RoundedImage
                                className="profilePic"
                                width="100%"
                                height="100%"
                                src={dummyImage}
                                alt="pic" />                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="William Jones" secondary="ID: 57898" />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <RoundedImage
                                className="profilePic"
                                width="100%"
                                height="100%"
                                src={dummyImage}
                                alt="pic" />                                            </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Work" secondary="ID: 57898" />
                </ListItem>
        </List>
        </CardContentWrapper>
        </CardWrapper>

    );
}
