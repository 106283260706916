import { Link } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";

const LinkLabel = styled(Link)`
  margin: ${props => props.mg || "auto"};
  opacity: ${props => props.opacity};
  color: ${props => props.color || "#8E8E8E"};
  margin-top: ${props => props.mt};
  font-size: 13px;
  width: -webkit-fill-available;
  text-align: start;
  font-weight: bold;

  b {
    font-weight: bold;
  }

  &.active {
    color: #ffffff;
  }
`;

export default LinkLabel;

Text.propTypes = {
  mg: PropTypes.string,
  mt: PropTypes.string,
  opacity: PropTypes.string,
  color: PropTypes.string,
};