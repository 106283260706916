import styled from "styled-components";

export const Field = styled.input`
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  width: 100%;
  height: 3rem;
  color: gray;
  padding-left: 20px;
  font-size: 16px;

  ::-webkit-input-placeholder,
  ::placeholder {
    color:  #716f6f};
  }

  :disabled {
    cursor: none;
  }
  input[type="number" i] {
    padding: 1px 2px;
    width: 93%;

  
}
`;
