import styled  from 'styled-components';

export const Layout = styled.div`
  padding: 0;
  margin: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
`
;
