import styled from "styled-components";
import { FlexContainer } from "../../atoms/FlexContainer";

export const Wrapper = styled(FlexContainer)`
  height: 80%;
  width: 100%;
  background: white;
  padding:1rem;
  flex-direction: column;
  left: 0;
  z-index: 2;
  border-radius:2rem;
  .ant-tabs {
width:100%
}
.ant-btn span{
  vertical-align: super;
  padding: 0 0.5rem;
}
.ant-tabs .ant-tabs-tab {
  width:50%;}
  .ant-tabs {
    height: 60%;

}
  
`;

export const TopCardsWrapper = styled(FlexContainer)`
height: 30%;
top: 10rem;
top: 14rem;
display: flex;
justify-content: start;
width: 100%;
`;
