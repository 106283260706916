import styled from "styled-components";

import { FlexContainer } from "../../atoms/FlexContainer";
export const CardWrapper = styled(FlexContainer)`
box-sizing: border-box;
width: 70%;
height: 60vh;
margin-top: 1rem;
background: #FFFFFF;
border: 2px solid #EFEEEB;
border-radius: 12px;
overflow: auto;
`;
