
import { Tabs, Tooltip } from 'antd';
import React, { useState } from 'react';

import WalletBalances from '../../molecules/Wallet/balances';
import { Button, Typography } from 'antd';
import { TopCardsWrapper, Wrapper } from "./styles";
import Card from '../../atoms/Card';
import { WalletTwoTone, FileAddOutlined, TransactionOutlined, FileTextOutlined, FileAddTwoTone } from '@ant-design/icons';
import NewWalletForm from '../../molecules/Wallet/newWallet';
import BoldText from '../../atoms/BoldText';
import AddWallet from "../../../../assets/images/wallet.png";
import { RoundedImage } from '../../atoms/RoundedImage';
import FormModal from '../../atoms/Modal';


const { Title } = Typography;
const { Meta } = Card;

export default function WalletPageContent() {

    const [tabsList, setTabsList] = useState([
        { tab: 'Wallet Balances', key: 'tab1', Icon: WalletTwoTone },
        { tab: 'New Wallet', key: 'tab2' },
        { tab: 'Transfer Status', key: 'tab3' },
        { tab: 'Transfer Wallet', key: 'tab4' },

    ]);
    const [state, setState] = useState({
        activeTab: "tab1"
    })

    const changeTab = activeKey => {
        console.log(activeKey);
        setState({
            activeTab: activeKey
        });
    };
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };
    return (
        <>
            <Wrapper>
                <BoldText>Wallets</BoldText>
                <TopCardsWrapper>

                    <Card bgfooter={'transparent'} pd={'0 1rem'} title="Balance" description='$5,379.37' btnTxt='ID:98765432345678' width={'40%'}
                        lbg={'linear-gradient(126.69deg, rgba(43, 57, 144, 0) 6.1%, #01ACED 71.35%)'}
                        bg={'linear-gradient(121.68deg, #2B3990 -3.41%, #01ABED 86.47%)'}
                        color={'white'}></Card>
                    {/* <TableCard /> */}
                    <Tooltip title="Add a new Wallet">
                        <RoundedImage src={AddWallet} width={"50px"} height={"50px"} style={{
                            float: "right", position: "absolute",
                            right: " 5%"
                        }}
                            onClick={showModal} />
                    </Tooltip>

                </TopCardsWrapper>
                <Tabs
                    defaultActiveKey="tab1"
                    activeKey={state.activeTab}
                    onChange={changeTab}

                >
                    <tabsList.TabPane key="tab1" tab={

                        <Button icon={<WalletTwoTone />}>Wallet Balances</Button>

                    }>
                        <WalletBalances />
                    </tabsList.TabPane>
                    <tabsList.TabPane key="tab2" tab={

                        <Button icon={<FileAddOutlined />}>New Wallet</Button>

                    }>
                        <NewWalletForm />
                    </tabsList.TabPane>

                </Tabs>
                <FormModal
                    open={open}
                    handleOk={handleOk}
                    confirmLoading={confirmLoading}
                    handleCancel={handleCancel}>  <NewWalletForm isModal={open}/></FormModal>
            </Wrapper>
        </>
    );
}
