import styled from "styled-components";
import { FlexContainer } from "../../atoms/FlexContainer";

export const Wrapper = styled(FlexContainer)`
  height: 100%;
  width: 100%;
  background: biege;
  position: absolute;
  left: 0;
  z-index: 2;
  @media (min-width: 768px) {
    width: 250px;
    position: unset;
  }
`;


export const ContentWrapper = styled(FlexContainer)`
width: 100%;
justify-content: flex-start;
  @media (min-width: 1025px) {
    background: biege;

  }
`;
export const HomeText = styled.div`
  width: 100%;
  text-align: center;
  padding: 4rem 0;
  padding-bottom: 2rem;
  p {
    margin-top: 20px;
    font-size: 18px;
    line-height: 29px;
    opacity: 0.7;
  }
`;

export const CardsWrapper = styled(FlexContainer)`
width: 100%;
height:30%;
justify-content: space-evenly;
`;

export const WidgetsWrapper = styled(FlexContainer)`
flex-direction: column;
// width:25%;
padding: 1rem;
`;
export const SectionWrapper = styled(FlexContainer)`
flex-direction: row;
width:100%;
justify-content: space-evenly;

`;