
import BoldText from '../../atoms/BoldText';
import {
    Button, Form, Divider,
} from 'antd';
import PropTypes from "prop-types";
import InputField from "../../atoms/Input";
import { FormFooter } from "./styles";

export default function NewContactForm({ isModal, handleClose,handleOk }) {

    return (

        <>
            <BoldText>Add New Contact</BoldText>
            <Divider />
            {/* <ItemWrapper> */}
                <Form.Item>
                    <InputField
                        rounded
                        transparent
                        type="text"
                        name="name"
                        placeholder="Wallet Name"
                    />
                </Form.Item>
                <Form.Item>
                    <InputField
                        type="number"
                        name="phone"
                        placeholder="PhoneNumber"
                    />
                </Form.Item>
                    <Form.Item>
                        <InputField
                            name="email"
                            type="email"
                            placeholder="some@host.com"
                        />
                    </Form.Item>
              
            {/* </ItemWrapper> */}
            {!isModal &&
                <FormFooter>
                    <Form.Item style={{ width: '50%' }}>
                        <Button type="primary" onClick={handleOk}>Add</Button>
                        <Button type="secondary" onClick={()=>handleClose}>Cancel</Button>

                    </Form.Item>
                </FormFooter>
            }
        </>
    );
}
NewContactForm.propTypes = {
    isModal: PropTypes.bool,
}