import styled from "styled-components";

import { FlexContainer } from "../../atoms/FlexContainer";

export const WidgetWrapper = styled(FlexContainer)`
height: 10vh;
background: linear-gradient(124.31deg, #2B3990 7.01%, #00AEEF 95.31%);
border-radius: 12px;
margin: 1rem 0;
width:100%;

`;

export const WidgetButton = styled.div`
width: 70%;
padding: 1rem;
background: #FFFFFF;
border-radius: 9px;
margin:auto;
svg{
vertical-align:middle;
margin-right:0.7rem;
}
`;
export const WidgetContent = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
text-align:center;
margin:auto;
color:#0096ffde;
`;
