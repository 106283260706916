import PropTypes from "prop-types";
import { Text } from "./styles";

export default function BoldText({ children,color }) {
  return <Text color={color}>{children}</Text>;
}

BoldText.propTypes = {
    children: PropTypes.node,
    color:PropTypes.string,
  };
  