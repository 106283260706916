
// import { Typography } from '@mui/material';
// import Button from '@mui/material/Button';
import { PlusOutlined } from '@ant-design/icons';

import React, { useState } from 'react';
import {
    Button, Form, Input, Radio, Select,
    InputNumber, Checkbox, Divider, Upload, Typography


} from 'antd';

import { Wrapper, StyledForm } from "../styles";
import InputField from '../../../atoms/Input';
const { TextArea } = Input;



export default function BulkForm() {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('vertical');
    const onFormLayoutChange = ({ layout }) => {
        setFormLayout(layout);
    };
    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 5,
                },
                wrapperCol: {
                    span: 16,
                },
            }
            : null;
    const buttonItemLayout =
    {
        wrapperCol: {
            span: 14,
            offset: 4,
        },
    }

    return (
        <>

            <Wrapper>


            <StyledForm>
                    <Form.Item label="Sending from: ">
                        <Select placeholder="Wallet"
                            options={[
                                {
                                    value: 'clientPayments',
                                    label: 'Client payments',
                                },
                                {
                                    value: 'investments',
                                    label: 'Investments',
                                },
                                {
                                    value: 'installment',
                                    label: 'Installment',
                                },
                                {
                                    value: 'staffSalary',
                                    label: 'Staff Salary',
                                }

                            ]}>
                            <Select.Option value="demo">Demo</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Sending to: ">
                        <Select placeholder="User Group"
                            options={[
                                {
                                    value: 'clients',
                                    label: 'Client payments',
                                },
                                {
                                    value: 'merchants',
                                    label: 'Investments',
                                },
                                {
                                    value: 'foremen',
                                    label: 'Installment',
                                },
                                {
                                    value: 'staffSalary',
                                    label: 'Staff Salary',
                                }

                            ]}>
                            <Select.Option value="demo">Demo</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Amount:">
                        <InputField placeholder='Amount' type="number" />
                    </Form.Item>
                    <Form.Item label="Add description:">
                        <TextArea rows={4} placeholder="Description" />
                    </Form.Item>
                   




                    <Form.Item valuePropName="checked">
                        <Checkbox value="A">Add Withdrawal Fees</Checkbox>
                        <Checkbox value="B">Add Withdrawal Tax</Checkbox>
                    </Form.Item>
                </StyledForm>
            </Wrapper>
        </>
    );
}
