
import { Button, Typography, Space } from 'antd';
import React, { useState } from 'react';
import ContactList from '../../molecules/Contacts';
import { WidgetsWrapper } from '../Dashboard/styles';
import { Wrapper, GroupsCards } from './styles';
import CreateContact from '../../molecules/Contacts/addContact';
import GroupOverview from '../../molecules/Contacts/overviewGroup';

export default function ContactsPageContent() {
    const { Link } = Typography;
    const [addContact, setAddContact] = useState(false);
    const [isCreateGroup, setIsCreateGroup] = useState(false);
    const [isOverview, setIsGroupOverview] = useState(false);
    const [group, setGroup] = useState("try");



    const setAdd = () => {
        setAddContact(true)
    };


    const handleCreateGroup = () => {
        console.log(isCreateGroup);
        setAdd();
        setIsCreateGroup(true);
    };
    const setDisable = () => {
        console.log(isCreateGroup);

        setIsCreateGroup(false);
        setAddContact(false)
    };
    const groups = [
        { name: 'group1', id: 1 },
        { name: 'group2', id: 2 },
        { name: 'group3', id: 3 },
        { name: 'group4', id: 4 },
        { name: 'group5', id: 5 },
        { name: 'group6', id: 6 },
        { name: 'group7', id: 7 },
        { name: 'group8', id: 8 },
        { name: 'group9', id: 9 },
        { name: 'group10', id: 10 },
        { name: 'group11', id: 11 },
        { name: 'group12', id: 12 },
        { name: 'group13', id: 13 },
        { name: 'group14', id: 14 },


    ]
    const openGroup = (group) => {
        setGroup(group);
        setIsGroupOverview(true);
        console.log("hjgfds open", isOverview);

    };
    const handleCloseGroup = () => {
        setIsGroupOverview(false);
        console.log("hjgfds", isOverview);

    };

    return (
        <>
            <Wrapper>
                <ContactList />
                <WidgetsWrapper>
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Button>
                           <a href="contacts/bulk-add">Import Contacts</a> 
                        </Button>

                        <GroupsCards

                        >
                            {groups.map((group) =>
                                <p key={group.id}>
                                    <Button type="primary" ghost onClick={() => openGroup(group.name)}>
                                        {group.name}
                                    </Button>
                                </p>
                            )
                            }
                        </GroupsCards>
                        <Button onClick={setAdd}>
                            Create Contact
                        </Button>
                        <Button onClick={handleCreateGroup}>
                            Create Group
                        </Button>
                    </Space>
                </WidgetsWrapper>
                {addContact && <CreateContact setDisable={setDisable} isCreateGroup={isCreateGroup} />}
                {/* {isOverview && <GroupOverview groupName={group} handleClose={handleCloseGroup} />} */}
            </Wrapper>
        </>
    );
}
