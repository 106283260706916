import styled from "styled-components";

import { FlexContainer } from "../../atoms/FlexContainer";

const NavLinkWrapper = styled(FlexContainer)`
  width: 80%;
  height: 37px;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;

  &.home {
    position: absolute;
    bottom: 8px;
    border-bottom: 1px solid #2a2a2a;
  }

  &.info {
    position: absolute;
    bottom: 32px;
    cursor: pointer;
  }

  svg {
    margin: auto 0;
    margin-left: 25px;
    width: 19px;
  }

  &.active {
    background: #0096ffde;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

  }
  padding: 0.7rem;

`;

export default NavLinkWrapper;
