import { Table } from 'antd';
import styled from "styled-components";
import { FlexContainer } from "../../atoms/FlexContainer";
import InputField from '../../atoms/Input';

export const Wrapper = styled(FlexContainer)`
box-sizing: border-box;
width: 95%;
flex-direction:column;
height: 100%;
margin-top: 1rem;
background: #FFFFFF;
border: 2px solid #EFEEEB;
border-radius: 12px;
overflow: auto;
.ant-table{
    height:80%;
}
`;
export const ContactsTable = styled(Table)`
height: 100%;
width: 100%;
.editable-cell {
    position: relative;
  }
  
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }
  
  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;
export const CreateContactWrapper = styled(FlexContainer)`
position:absolute;
box-sizing: border-box;
width: 95%;
flex-direction:column;
height: 100%;
margin-top: 1rem;
background: #FFFFFF;
border: 2px solid #EFEEEB;
border-radius: 12px;
overflow: auto;
.ant-table{
    height:80%;
    
}

form{
    width:100%;
    padding: 1rem;
    div.btns{
        display:flex;
        width: 15%;
        justify-content:space-evenly;
    }
}
`;
export const CreateButtonWrapper = styled.div`
display: flex;
float: right;
justify-content: space-between;
`;

export const CreateGroupInput = styled(InputField)`
margin: 1rem 0;
width: 40%;
float: left`;

export const FormFooter = styled(FlexContainer)`
width:100%;
padding: unset;
button{
  width:50%;
}`;