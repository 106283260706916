import { Link } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FlexContainer } from "../../atoms/FlexContainer";

export const NavLinkWrapper = styled(FlexContainer)`
  width: 100%;
  height: 37px;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;

  &.home {
    position: absolute;
    bottom: 8px;
    border-bottom: 1px solid
      ${props =>
        props.themeColor === "BRIGHT"
          ? "#EAEAEA"
          : props =>
              props.themeColor === "PURPLE"
                ? "#222A39"
                : props.themeColor === "GREEN"
                ? "#27302E"
                : props.themeColor === "BLUE"
                ? "#1A394D"
                : "#2a2a2a"};
  }

  &.info {
    position: absolute;
    bottom: 32px;
    cursor: pointer;
  }

  svg {
    margin: auto 0;
    margin-left: 25px;
    width: 19px;
  }

  &.active {
    background: ${props =>
      props.themeColor === "BRIGHT"
        ? "#EAEAEA"
        : props =>
            props.themeColor === "PURPLE"
              ? "#222A39"
              : props.themeColor === "GREEN"
              ? "#27302E"
              : props.themeColor === "BLUE"
              ? "#1A394D"
              : "#2a2a2a"};
  }
`;


const LinkLabel = styled(Link)`
  margin: ${props => props.mg || "auto"};
  opacity: ${props => props.opacity};
  color: ${props => props.color || "#8E8E8E"};
  margin-top: ${props => props.mt};
  font-size: 13px;
  width: -webkit-fill-available;
  text-align: start;
  font-weight: bold;

  b {
    font-weight: bold;
  }

  &.active {
    color: ${props => (props.themeColor === "BRIGHT" ? "#2a2a2a" : "#ffffff")};
      font-weight: bold;
  }

  }
`;

export default LinkLabel;

Text.propTypes = {
  mg: PropTypes.string,
  mt: PropTypes.string,
  opacity: PropTypes.string,
  color: PropTypes.string,
};

export const CustomWrapper = styled.div`
span{padding: 1rem;

}
`;