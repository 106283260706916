import styled from "styled-components";
import DefaultBlue from "../../../../assets/images/rectblue.png";
import DefaultWhite from "../../../../assets/images/white.jpeg";
import BoldText from "../../atoms/BoldText";
import { FlexContainer } from "../../atoms/FlexContainer";

export const Wrapper = styled.div`
min-width: 100%;
display: flex;
scroll-behavior: smooth;
flex-direction: column;
overflow-x:hidden;
}
`;

export const BoxContainer = styled(FlexContainer)`
display: inline-flex;
height:100vh;
min-width: 100vw;
background:white;
}
`;
export const LeftBox = styled.div`
  width: 40%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin:auto;
  height:50%;
  a{color:white}
`;
export const IntroHead = styled(BoldText)`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  padding: 2rem;
  width: 60%;
  margin: auto;
  color: #2b3990;
`;
// export const IntroHeadWrapper=styled(BoldText)`
// font-family: 'Nunito';
// font-style: normal;
// font-weight: 500;
// font-size: 25px;
// line-height: 30px;
// letter-spacing: 0.3px;
// text-transform: capitalize;   padding:2rem;
// width:60%;
// margin:auto;
// `;
export const RightBox = styled.div`
width: 60%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, calc(0% + 30vh) 100%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, calc(0% + 22vh) 100%);

  background: linear-gradient(
    326.16deg,
    #1672be 0%,
    #1672be 0.01%,
    #00aeef 100%
  );
  background-repeat: no-repeat;
  display: flex;
  height:100vh;
  margin:auto;

 
`;
export const RightBoxImage = styled.div`
margin-top: 8rem;
    height: 100%;
    // position: absolute;
`;
export const BackDropImg = styled.img`
border-radius:2rem;
width:50rem;`;
export const FrontDropImg = styled.img`
margin: -20rem -40rem;
width: 50rem;
`;

export const Navigation = styled.div`
  width: 60%;
  margin-left: -2vh;
  text-align: center;
  display: inline-flex;
  color: white;
  text-align: right;
  h3 {
    padding: 1rem;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }
`;

export const FeaturesContainer = styled.div`
min-width: 100%;
height:100vh;
// padding: 2rem;
background-image: url(${DefaultWhite}) ;
background-repeat: no-repeat;
flex-direction: column;
display:flex;
background-size: cover;
}
`;
export const LeftFeatures = styled.div`
  width: 50%;
  padding: 5px 11vh 5px 5px;
  text-align: center;
  height: 100%;
`;
export const RightFeatures = styled.div`
  width: 50%;
  background-repeat: no-repeat;
  text-align: center;
  height: 100%;
`;

export const FeaturesWrapper = styled.div`
  min-width: 100%;
  display: inline-flex;
  min-height: 65%;
  max-height: 70%;
`;

export const NewsletterWrapper = styled.div`
  max-width: 60%;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-height: 20vh;
  align-self: center;
  padding: 6rem;
  span {
    padding: 1rem;
  }
`;

export const Intro = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.3px;
  text-transform: capitalize;
`;
